import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StoreIcon from '@mui/icons-material/Store';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AppsIcon from '@mui/icons-material/Apps';
import { BottomNavigation, BottomNavigationAction, Paper, Theme } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Tapbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);

  useEffect(() => {
    switch (location.pathname) {
      case '/overview':
        setValue(0);
        break;
      case '/list':
        setValue(1);
        break;
      case '/time':
        setValue(2);
        break;
      case '/firm':
        setValue(3);
        break;
      default:
        setValue(0);
        break;
    }
  });

  const handleTapbarClick = (uri: string) => {
    if (location.pathname === uri) {
      navigate('/');
      setTimeout(() => navigate(uri), 0);
    } else {
      navigate(uri);
    }
  };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 10 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
      >
        <BottomNavigationAction label="Übersicht" icon={<AppsIcon />} onClick={() => handleTapbarClick('/overview')} />
        <BottomNavigationAction label="Rapporte" icon={<FormatListBulletedIcon />} onClick={() => handleTapbarClick('/list')} />
        <BottomNavigationAction label="Zeiterfassung" icon={<AccessTimeIcon />} onClick={() => handleTapbarClick('/time')} />
        <BottomNavigationAction label="Firma" icon={<StoreIcon />} onClick={() => handleTapbarClick('/firm')} />
      </BottomNavigation>
    </Paper>
  );
};

export default Tapbar;