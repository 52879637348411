export type RapportFilter = {
  search: string;
  jobDone: boolean | undefined;
  billed: boolean | undefined;
};

export const defaultRapportFilter = {
  search: "",
  jobDone: undefined,
  billed: undefined,
};