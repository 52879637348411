import { useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { MetaContext } from './Provider/MetaContextProvider';
import { useNavigate } from 'react-router-dom';
import ProfileContextmenu from './Contextmenu/ProfileContextmenu';
import SchochLogo from '../svg/SchochLogo';
import SupportIcon from '@mui/icons-material/Support';
import { Theme, useMediaQuery } from '@mui/material';
import DynamicIcon from './DynamicIcon';

type TitlebarProps = {
  title: string;
  iconName: string;
}

const Titlebar = (props: TitlebarProps) => {
  const context = useContext(MetaContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isXsOrSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const mobileTitle = () => {
    return (
      <Box>
        <AppBar>
          <Toolbar>
            { context.isAuthenticated && <IconButton
              edge="start"
              onClick={() => navigate('/overview')}
            >
              <SchochLogo size={60} />
            </IconButton> }
            <Typography
              sx={{ flexGrow: 1, textAlign: 'center' }}
              variant="h5"
            >
              {props.title}
            </Typography>
            {context.isAuthenticated &&
            <Box display='flex' gap={2}>
              <IconButton
                sx={{ padding: 0, paddingRight: 1, cursor: 'pointer' }}
                edge="end"
                color="inherit"
                onClick={() => navigate('/support')}
              >
                <SupportIcon fontSize='large' />
              </IconButton>
              <IconButton
                sx={{ padding: 0, paddingRight: 1, cursor: 'pointer' }}
                edge="end"
                color="inherit"
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <PersonIcon fontSize='large' />
              </IconButton>
            </Box>}
          </Toolbar>
          <ProfileContextmenu isOpen={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
        </AppBar>
        <Box padding={2}>
          <Toolbar />
        </Box>
      </Box>
    );
  };

  const pcTitle = () => {
    return (
      <Box>
        <Box sx={ (theme: Theme) => ({ position: 'fixed', width: '100%', display: 'flex', flexDirection: 'row', background: theme.palette.background.paper, gap: 1, padding: 2, alignItems: 'center', borderBottom: 2, borderColor: theme.palette.grey[800], zIndex: 100 })}>
          <DynamicIcon iconName={props.iconName} />
          <Typography variant="h6">{props.title}</Typography>
        </Box>
        <Box padding={4} />
      </Box>
    );
  };

  return (
    <Box>
      {isXsOrSm && (mobileTitle())}
      {!isXsOrSm && (pcTitle())}
    </Box>
  );
}

export default Titlebar;