import { MeasurementsDto } from "../dto/measurements-dto";
import { Measurements } from "../models/measurements";

const MeasurementsHelper = {
  measurementsFromDto: (measurementsDto: MeasurementsDto): Measurements => {
    return {
      id: measurementsDto.id,
      fi: measurementsDto.fi,
      nin: measurementsDto.nin,
      tnS: measurementsDto.tnS,
      tnC: measurementsDto.tnC,
      tnCS: measurementsDto.tnCS,
      counter: measurementsDto.counter,
      inA: measurementsDto.inA,
      rIso: measurementsDto.rIso,
      ikA: measurementsDto.ikA,
      mOhm: measurementsDto.mOhm,
    };
  },

  measurementsToDto: (measurements: Measurements): MeasurementsDto => {
    return {
      id: measurements.id,
      fi: measurements.fi,
      nin: measurements.nin,
      tnS: measurements.tnS,
      tnC: measurements.tnC,
      tnCS: measurements.tnCS,
      counter: measurements.counter,
      inA: measurements.inA,
      rIso: measurements.rIso,
      ikA: measurements.ikA,
      mOhm: measurements.mOhm,
    };
  },
};

export default MeasurementsHelper;