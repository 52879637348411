import { Button, Stack } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { RapportContext } from '../Provider/RapportContextProvider';

const SignatureItem: React.FC = () => {
  const rapportContext = useContext(RapportContext)
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const getPosition = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>): { x: number, y: number } => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };

    const rect = e.currentTarget.getBoundingClientRect();
    let clientX, clientY;

    if (e.type.startsWith('mouse')) {
      const mouseEvent = e as React.MouseEvent<HTMLCanvasElement>;
      clientX = mouseEvent.clientX;
      clientY = mouseEvent.clientY;
    } else {
      const touchEvent = e as React.TouchEvent<HTMLCanvasElement>;
      clientX = touchEvent.touches[0].clientX;
      clientY = touchEvent.touches[0].clientY;
    }

    return {
      x: clientX - rect.left,
      y: clientY - rect.top
    };
  };

  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    setIsDrawing(true);
    const context = canvasRef.current?.getContext('2d');
    if (!context) return;

    const { x, y } = getPosition(event);
    context.beginPath();
    context.moveTo(x, y);
  };

  const continueDrawing = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;

    const context = canvasRef.current?.getContext('2d');
    if (!context) return;

    const { x, y } = getPosition(event);
    context.lineTo(x, y);
    context.stroke();
  };

  const endDrawing = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    setIsDrawing(false);
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      context.closePath();
    }

    saveCanvas();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const loadCanvas = () => {
    const signature = rapportContext.rapport.signature;
    if (signature) {
      const context = canvasRef.current?.getContext('2d');
      if (context) {
        const image = new Image();
        image.onload = () => {
          context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height); // Clear the canvas before drawing
          context.drawImage(image, 0, 0);
        };
        image.src = "data:image/png;base64," + signature;
      }
    }
  };

  const saveCanvas = () => {
    const canvas = canvasRef.current;
    if (!!!canvas) return;
    const contents = canvas.toDataURL('image/png');
    rapportContext.setRapport(prev => ({ ...prev, signature: contents.split(',')[1], signatureId: 0 }));
  };

  const onResize = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const context = canvas.getContext('2d');
    if (context) {
      context.lineJoin = 'round';
      context.lineCap = 'round';
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    loadCanvas();
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Stack marginTop={2} gap={2}>
      <canvas
        ref={canvasRef}
        style={{ border: '1px solid #000' }}
        onMouseDown={startDrawing}
        onTouchStart={startDrawing}
        onMouseMove={continueDrawing}
        onTouchMove={continueDrawing}
        onMouseUp={endDrawing}
        onTouchEnd={endDrawing}
      />
      <Button variant='contained' sx={{ width: 200, alignSelf: 'center' }} onClick={clearCanvas}>
        Zurücksetzen
      </Button>
    </Stack>
  );
};

export default SignatureItem;
