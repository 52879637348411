import Titlebar from "../components/Titlebar";
import { Alert, Box, Button, Divider, IconButton, Link, Paper, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from "react-router-dom";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import RapportService from "../services/rapport-service";
import { Rapport } from "../models/rapport";
import RapportCard from "../components/RapportCard";
import AddIcon from '@mui/icons-material/Add';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MeContext } from "../components/Provider/MeContextProvider";
import { SchochStack } from "../styles/styles";
import PdfSvgIcon from "../svg/PdfSvgIcon";
import NoFirmAlert from "../components/NoFirmAlert";
import OverviewArea from "../components/Charts/OverviewArea";

const OverviewPage: React.FC = () => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const navigate = useNavigate();
  const [rapports, setRapports] = useState<Rapport[]>([]);

  const fetchRapports = async () => {
    try {
      context.setIsLoading(true);
      const data = await RapportService.getRapports(undefined, undefined, undefined, undefined, undefined, false, false, true); // Performance: This gets all rapports, but they aren't all used, 
      if (!!data) {
        setRapports(data);
      } else {
        context.setAlertMessage("Daten konnten nicht geladen werden.");
        context.setAlertSeverity("warning");
      }
    } catch (error) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  useEffect(() => {
    if (meContext.currentFirm !== null) {
      fetchRapports();
    }
  }, [meContext.currentFirm]);

  const noFirmDisplay = () => {
    return (
      <Stack sx={{ padding: { sm: 2 }, gap: 2 }}>
        <NoFirmAlert />
        <SchochStack>
          <Typography variant="h6">Infos zu SchochAUFTRAG 'Alfred'</Typography>
          <Box display='flex' flexDirection='row' gap={2}>
            <Link sx={{ alignSelf: 'center', }} href="/pdfs/schoch-auftrag.pdf" download='schoch-auftrag.pdf'>
              <PdfSvgIcon size={60} />
            </Link>
            <Typography alignSelf='center' textAlign='center'>Broschüre herunterladen</Typography>
          </Box>
        </SchochStack>
      </Stack>
    );
  };

  const newFirmDisplay = () => {
    return (
      <SchochStack>
        <Box>
          <Typography variant="h6">Rapporte</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <Paper sx={{ borderRadius: 0, padding: 2, alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="subtitle2">Offene Rapporte</Typography>
            <Typography variant="h6">120</Typography>
          </Paper>
          <Paper sx={{ borderRadius: 0, padding: 2, alignItems: 'center', textAlign: 'center', background: theme => theme.palette.primary.lighter }}>
            <Typography variant="subtitle2">Heutige Rapporte</Typography>
            <Typography variant="h6">120</Typography>
          </Paper>
          <Paper sx={{ borderRadius: 0, padding: 2, alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="subtitle2">Erledigte Rapporte</Typography>
            <Typography variant="h6">120</Typography>
          </Paper>
        </Box>
        <OverviewArea />
      </SchochStack>
    );
  };

  const firmDisplay = () => {
    return (
      <Stack padding={2} gap={2}>
        <Box 
          sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5">Meine Rapporte ({rapports.length})</Typography>
          <IconButton
            onClick={() => navigate("/list")}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <Swiper
          style={{ width: '100%', height: '100%' }}
          modules={[Pagination, Navigation]}
          slidesPerView={'auto'}
          spaceBetween={80}
          centeredSlides
          navigation
          pagination={{ dynamicBullets: true, clickable: true }}
          grabCursor
        >
        {rapports.map((rapport, i) => (
          <SwiperSlide key={i} style={{ width: 'unset' }}>
            <RapportCard rapport={rapport} setRapports={setRapports} />
          </SwiperSlide>
        ))}
        </Swiper>
        {(rapports.length === 0 && !context.isLoading) && <Alert severity="info">Es wurden keine Rapporte gefunden.</Alert>}
        <Divider />
        <Typography variant="h5">Neuer Rapport</Typography>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            margin: (theme) => theme.spacing(3),
            width: 250,
            height: 150,
            color: theme => theme.palette.grey[700],
            backgroundColor: (theme) => theme.palette.grey[100],
            border: 1, borderColor: (theme) => theme.palette.grey[400],
          }}
          onClick={() => navigate('/rapport')}>
          <AddIcon fontSize="large"/>
        </Button>
      </Stack>
    );
  };

  return (
    <Stack>
      <Titlebar title="Übersicht" iconName="Apps" />
      <Stack sx={{ padding: 2 }}>
        {meContext.currentFirm === null && noFirmDisplay()}
        {meContext.currentFirm !== null && firmDisplay()}
      </Stack>
    </Stack>
  );
};

export default OverviewPage;