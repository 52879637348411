import { useContext } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MetaContext } from "./Provider/MetaContextProvider";

const Loading: React.FC = () => {
  const context = useContext(MetaContext);

  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={context.isLoading}
    >
      <CircularProgress
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
        color="inherit"
      />
    </Backdrop>
  );
};

export default Loading;