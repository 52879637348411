import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Address, defaultAddress } from "../../models/address";

type AddressDialogProps = {
  address: Address | null;
  isOpen: boolean;
  onSave: (address: Address) => void;
  onClose: () => void;
};

export const AddressDialog = (props: AddressDialogProps) => {
  const [newAddress, setNewAddress] = useState<Address>(defaultAddress);

  const updateAddress = (property: keyof Address) => (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    setNewAddress(prev => ({...prev, [property]: newValue}));
  };

  useEffect(() => {
    if (props.address !== null) {
      setNewAddress(props.address);
    }
  }, [props.address]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogTitle>Adresse speichern</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='row' flexWrap='wrap' paddingTop={1} gap={2}>
          <TextField
            size="small"
            label="Vorname"
            value={newAddress.firstname}
            onChange={updateAddress("firstname")}
          />
          <TextField
            size="small"
            label="Nachname"
            value={newAddress.lastname}
            onChange={updateAddress("lastname")}
          />
          <TextField
            size="small"
            label="Strasse"
            value={newAddress.street}
            onChange={updateAddress("street")}
          />
          <TextField
            size="small"
            label="PLZ"
            value={newAddress.postalCode}
            onChange={updateAddress("postalCode")}
          />
          <TextField
            size="small"
            label="Ort"
            value={newAddress.city}
            onChange={updateAddress("city")}
          />
          <TextField
            size="small"
            label="Kanton"
            value={newAddress.canton}
            onChange={updateAddress("canton")}
          />
          <TextField
            size="small"
            label="Land"
            value={newAddress.country}
            onChange={updateAddress("country")}
          />
          <TextField
            size="small"
            label="Telefon-Nr."
            value={newAddress.phone}
            onChange={updateAddress("phone")}
          />
          <TextField
            size="small"
            label="Email"
            value={newAddress.mail}
            onChange={updateAddress("mail")}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{paddingBottom: 2, paddingRight: 2}}>
        <Button onClick={() => { props.onSave(newAddress); setNewAddress(defaultAddress); }}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressDialog;