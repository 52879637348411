export type Address = {
  id: number;
  firstname: string;
  lastname: string;
  street: string;
  postalCode: string;
  canton: string;
  city: string;
  country: string;
  phone: string;
  mail: string;
};

export const defaultAddress: Address = {
  id: 0,
  firstname: '',
  lastname: '',
  street: '',
  postalCode: '',
  canton: '',
  city: '',
  country: '',
  phone: '',
  mail: '',
};