import { Stack } from '@mui/material';
import Titlebar from '../components/Titlebar';
import { useContext } from 'react';
import FirmDetails from '../components/FirmDetails';
import NoFirmDetails from '../components/NoFirmDetails';
import { MeContext } from '../components/Provider/MeContextProvider';

const FirmPage: React.FC = () => {
  const meContext = useContext(MeContext);

  return (
    <Stack>
      <Titlebar title="Firma" iconName="Store" />
      { !!meContext.currentFirm ? <FirmDetails /> : <NoFirmDetails /> }
    </Stack>
  );
}

export default FirmPage;