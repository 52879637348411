import { Dialog, DialogTitle, DialogContent, Box, Avatar, Typography, Divider, Stack } from "@mui/material";
import { EntryButton } from "../../styles/styles";
import { useContext, useState } from "react";
import { RapportContext } from "../Provider/RapportContextProvider";
import DynamicIcon from "../DynamicIcon";
import ItemEntry from "../../models/item-entry";
import ItemDialog from "./ItemDialog";

type EntryOverviewProps = {
  open: boolean;
  closeDialog: () => void;
};

const EntryOverview = (props: EntryOverviewProps) => {
  const rapportContext = useContext(RapportContext);
  const [currentItem, setCurrentItem] = useState<ItemEntry | null>(null)

  const handleAddItem = (item: ItemEntry) => {
    const index = rapportContext.rapport.entries.findIndex(entry => entry.id === item.id);

    if (index !== -1) {
      const updatedEntries = [...rapportContext.rapport.entries];
      updatedEntries[index] = { ...updatedEntries[index], isActive: true };
  
      rapportContext.setRapport({
        ...rapportContext.rapport,
        entries: updatedEntries,
      });
    }
    setCurrentItem(item);
    props.closeDialog();
  };

  return (
    <Box>
      <Dialog open={props.open} onClose={props.closeDialog}>
        <DialogTitle>Fügen Sie ein Element hinzu:</DialogTitle>
        <DialogContent>
          <Box>
            <Stack>
            {rapportContext.rapport.entries.map(item => (
              <EntryButton
                key={item.id}
                disabled={item.isActive === true}
                onClick={() => handleAddItem(item)}
              >
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: 2, }}>
                  <Avatar
                    sx={{ backgroundColor: (theme) => item.isActive === true ? undefined : theme.palette.primary.main }}
                    variant='rounded'
                  >
                    <DynamicIcon iconName={item.iconName} />
                  </Avatar>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                      textAlign: 'center',
                      color: (theme) =>
                        item.isActive === true
                          ? undefined
                          : theme.palette.text.primary
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </EntryButton>
            ))}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <ItemDialog itemKey={!!currentItem ? currentItem.beName : null} itemName={!!currentItem ? currentItem.name : null} isOpen={currentItem !== null} onClose={() => {setCurrentItem(null)}} />
    </Box>
  );
}

export default EntryOverview;