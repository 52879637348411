import { Stack, TextField } from "@mui/material";
import { SchochStack } from "../../styles/styles";
import { Rapport } from "../../models/rapport";
import { ChangeEvent, useContext } from "react";
import { RapportContext } from "../Provider/RapportContextProvider";

const RapportInfoItem = () => {
  const rapportContext = useContext(RapportContext);

  const handleInputChange = (key: keyof Rapport) => (evt: ChangeEvent<HTMLInputElement>) => {
    rapportContext.setRapport(prev => ({
      ...prev,
      [key]: evt.target.value,
    }));
  };

  return (
    <Stack gap={2} marginTop={2}>
    <SchochStack>
      <TextField
        label="Auftrag erteilt durch"
        value={rapportContext.rapport.reporter}
        onChange={handleInputChange('reporter')}
      />
      <TextField
        label="Telefonische Rückfragen"
        value={rapportContext.rapport.phoneNr}
        onChange={handleInputChange('phoneNr')}
      />
      <TextField
        label="Kostenstelle"
        value={rapportContext.rapport.cost}
        onChange={handleInputChange('cost')}
      />
      <TextField
        label="Zähler-Nr."
        value={rapportContext.rapport.counter}
        onChange={handleInputChange('counter')}
      />
      <TextField
        label="Weitere Informationen"
        value={rapportContext.rapport.more}
        onChange={handleInputChange('more')}
        rows={4}
        multiline
      />
    </SchochStack>
  </Stack>
  );
};

export default RapportInfoItem;