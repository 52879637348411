import { JobDto } from "../dto/job-dto";
import { Job } from "../models/job";

const JobHelper = {
  jobFromDto: (jobDto: JobDto): Job => {
    return {
      id: jobDto.id,
      mieleWaschmaschine: jobDto.mieleWaschmaschine,
      schemaunterlagenErgänzt: jobDto.schemaunterlagenErgänzt,
      beschriftung: jobDto.beschriftung,
      materialBestellen: jobDto.materialBestellen,
      stoerungsquelleGesucht: jobDto.stoerungsquelleGesucht,
      defekteBauteile: jobDto.defekteBauteile,
      neuesBauteil: jobDto.neuesBauteil,
      thermorelaisEingebaut: jobDto.thermorelaisEingebaut,
      drehrichtungskontrolle: jobDto.drehrichtungskontrolle,
      funktionskontrolle: jobDto.funktionskontrolle,
      isolationsmessung: jobDto.isolationsmessung,
      inbetriebnahme: jobDto.inbetriebnahme,
      einzug: jobDto.einzug,
      instruktion: jobDto.instruktion,
      fremdmaterial: jobDto.fremdmaterial,
      messung: jobDto.messung,
    };
  },
  
  jobToDto: (job: Job): JobDto => {
    return {
      id: job.id,
      mieleWaschmaschine: job.mieleWaschmaschine,
      schemaunterlagenErgänzt: job.schemaunterlagenErgänzt,
      beschriftung: job.beschriftung,
      materialBestellen: job.materialBestellen,
      stoerungsquelleGesucht: job.stoerungsquelleGesucht,
      defekteBauteile: job.defekteBauteile,
      neuesBauteil: job.neuesBauteil,
      thermorelaisEingebaut: job.thermorelaisEingebaut,
      drehrichtungskontrolle: job.drehrichtungskontrolle,
      funktionskontrolle: job.funktionskontrolle,
      isolationsmessung: job.isolationsmessung,
      inbetriebnahme: job.inbetriebnahme,
      einzug: job.einzug,
      instruktion: job.instruktion,
      fremdmaterial: job.fremdmaterial,
      messung: job.messung,
    };
  },
}

export default JobHelper;