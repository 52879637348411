import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

type DialogTitlebarProps = {
  onClose: () => void;
  title: string;
}

const DialogTitlebar = (props: DialogTitlebarProps) => {
  return (
    <AppBar sx={{ position: 'relative' }}>
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        onClick={props.onClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>{props.title}</Typography>
    </Toolbar>
  </AppBar>
  );
};

export default DialogTitlebar;