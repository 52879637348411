import { Alert, Snackbar } from "@mui/material";
import { useContext } from "react";
import { MetaContext } from "./Provider/MetaContextProvider";

const AlertSnackbar: React.FC = () => {
  const context = useContext(MetaContext);

  return (
    <Snackbar
      open={!!context.alertMessage}
      autoHideDuration={6000}
      onClose={() => context.setAlertMessage(null)}
    >
      <Alert
        sx={{ width: '100%', marginBottom: '50px' }}
        severity={context.alertSeverity}
        onClose={() => context.setAlertMessage(null)}>
        {context.alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;