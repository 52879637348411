import { ButtonProps, Button, StackProps, Stack, styled, Box, BoxProps, MenuProps, Menu, alpha, Slide, ToggleButtonGroup, ToggleButton, TableCell, TableCellProps } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";

// Info: Contextmenu

export const Contextmenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

// Info: Container

export const SchochStickyRow = styled((props: BoxProps) => (<Box {...props} />))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'sticky',
  zIndex: 5,
  border: `1px solid black`,
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
  justifySelf: 'center',
  backgroundColor: theme.palette.grey[100],
}));

export const ConditionalSchochStack = styled((props: StackProps) => (<Stack {...props} />))(({ theme }) => ({
  [theme.breakpoints.not('xs')]: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid black`,
    padding: theme.spacing(2),
  },
  flex: 1,
  gap: theme.spacing(2),
}));

export const SchochStack = styled((props: StackProps) => (<Stack {...props} />))(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  gap: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  border: `1px solid black`,
}));

export const BoxXs = styled((props: BoxProps) => (<Box {...props}/>))(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const BoxSm = styled((props: BoxProps) => (<Box {...props}/>))(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    display: 'none',
  },
})); 

// Info: Entry Menu

export const EntryButton = styled((props: ButtonProps) => (<Button {...props} />))(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  cursor: 'pointer',
  transition: 'opacity 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  '&:not(:first-of-type)': {
    borderTop: '0px',
  },
  borderRadius: '0px',
}));

// Info: Transition

export const SlideUpTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Info: Table

export const ConditionalTableCell = styled((props: TableCellProps) => (<TableCell {...props} />))(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    display: 'none',
  },
}));