import MaterialAPI from "../api/material-api";
import MaterialHelper from "../helpers/material-helper";
import { Eldas } from "../models/eldas";
import { Npk } from "../models/npk";
import { Steiger } from "../models/steiger";

const MaterialService = {
  getSteigerMaterial: async (id?: number): Promise<Steiger[]> => {
    const materialDtos = await MaterialAPI.getSteigerMaterial(id);

    return materialDtos.map((materialDto) =>
      MaterialHelper.steigerFromDto(materialDto)
    );
  },

  searchSteigerMaterial: async (page?: number, pageSize?: number, search?: string): Promise<Steiger[]> => {
    const materialDtos = await MaterialAPI.searchSteigerMaterial(page, pageSize, search);
    
    return materialDtos.map((materialDto) =>
      MaterialHelper.steigerFromDto(materialDto)
    );
  },

  searchEldasMaterial: async (page?: number, pageSize?: number, search?: string): Promise<Eldas[]> => {
    const materialDtos = await MaterialAPI.searchEldasMaterial(page, pageSize, search);

    return materialDtos.map((materialDto) =>
      MaterialHelper.eldasFromDto(materialDto)
    );
  },

  getNpkMaterial: async (id?: number): Promise<Npk[]> => {
    const materialDtos = await MaterialAPI.getNpkMaterial(id);

    return materialDtos.map((materialDto) =>
      MaterialHelper.npkFromDto(materialDto)
    );
  },

  searchNpkMaterial: async (page?: number, pageSize?: number, search?: string): Promise<Npk[]> => {
    const materialDtos = await MaterialAPI.searchNpkMaterial(page, pageSize, search);

    return materialDtos.map((materialDto) =>
      MaterialHelper.npkFromDto(materialDto)
    );
  },

  loadPrices: async (): Promise<string> => {
    return await MaterialAPI.loadPrices();
  },

  cancelPriceLoading: async (): Promise<boolean> => {
    return await MaterialAPI.cancelPriceLoading();
  },
}

export default MaterialService;