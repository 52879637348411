import axios from "axios";
import { FirmDto } from "../dto/firm-dto";
import authorizedApi from "./api";
import config from "../config";
import { CreateUpdateFirmDto } from "../dto/create-update-firm-dto";

const FirmAPI = {
  firmExists: async (id: string): Promise<boolean> => {
    const response = await axios.get(`${config.apiUrl}/firms/${id}/exists`);
    return response.data as boolean;
  },

  syncCatalogLicences: async (): Promise<FirmDto> => {
    const response = await authorizedApi.put('/firms/licences');
    return response.data as FirmDto;
  },

  getPriceStatus: async (): Promise<boolean> => {
    const response = await authorizedApi.get('firms/prices-status');
    return response.data as boolean;
  },

  getFirm: async (id: string): Promise<FirmDto> => {
    const response = await authorizedApi.get(`/firms/${id}`);
    return response.data as FirmDto;
  },

  createFirm: async (id: string, createUpdateFirmDto: CreateUpdateFirmDto): Promise<string> => {
    const response = await authorizedApi.post(`/firms/${id}`, createUpdateFirmDto);
    return response.data as string;
  },

  updateFirm: async (id: string, createUpdateFirmDto: CreateUpdateFirmDto): Promise<string> => {
    const response = await authorizedApi.put(`/firms/${id}`, createUpdateFirmDto);
    return response.data as string;
  },

  deleteFirm: async (id: string) => {
    await authorizedApi.delete(`/firms/${id}`);
  },
}

export default FirmAPI;