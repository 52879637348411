import UserFirmAPI from "../api/user-firm-api";
import AuthService from "./user-service";
import { User } from "../models/user";
import UserHelper from "../helpers/user-helper";
import FirmHelper from "../helpers/firm-helper";
import { Firm } from "../models/firm";
import { AdminPermissionDto } from "../dto/users/admin-permission-dto";
import { UserFirmConnectDto } from "../dto/users/user-firm-connect-dto";
import { UserFirmJunctionDto } from "../dto/users/user-firm-junction-dto";
import { WorkloadAmountDto } from "../dto/users/workload-amount-dto";

const UserFirmService = {
  getAllUsers: async (searchTerm?: string): Promise<User[]> => {
    const userDtos = await UserFirmAPI.getAllUsers(searchTerm);
    const users = userDtos.map((userDto) =>
      UserHelper.userFromDto(userDto)
    );
    
    return users;
  },

  getFirmOfUser: async (): Promise<Firm | null> => {
    const firmDto = await UserFirmAPI.getFirmOfUser();

    if (firmDto === null) {
      return null;
    }
    
    return FirmHelper.firmFromDto(firmDto);
  },

  getUserLicence: async (): Promise<string | null> => {
    return await UserFirmAPI.getUserLicence();
  },

  getMemberCount: async (admin?: boolean): Promise<number | null> => {
    return await UserFirmAPI.getMemberCount(admin);
  },

  getAvailableUsersQuarantine: async (): Promise<string[]> => {
    return await UserFirmAPI.getAvailableUsersQuarantine();
  },

  checkAlfredConnectionQuarantine: async (firmLicence: string, userLicence: string): Promise<boolean> => {
    const userFirmConnectDto: UserFirmConnectDto = { firmLicence: firmLicence, userLicence: userLicence };
    return await UserFirmAPI.checkAlfredConnectionQuarantine(userFirmConnectDto);
  },

  checkValidConnectionQuarantine: async (firmLicence: string, userLicence: string): Promise<boolean> => {
    const userFirmConnectDto: UserFirmConnectDto = { firmLicence: firmLicence, userLicence: userLicence };
    return await UserFirmAPI.checkValidConnectionQuarantine(userFirmConnectDto);
  },

  joinFirm: async (firmLicence: string, userLicence: string, userId: number, isAdmin: boolean) => {
    const userFirmJunctionDto: UserFirmJunctionDto = { userLicence: userLicence, userId: userId, firmLicence: firmLicence, isAdmin: isAdmin };
    await UserFirmAPI.joinFirm(userFirmJunctionDto);
    await AuthService.refreshToken(); // Info: The firm is stored in the access token and has to be updated when firm is updated.
  },

  leaveFirm: async (firmLicence: string, userLicence: string, userId: number) => {
    const userFirmJunctionDto: UserFirmJunctionDto = { firmLicence: firmLicence, userLicence: userLicence, userId: userId, isAdmin: false };
    await UserFirmAPI.leaveFirm(userFirmJunctionDto);
    await AuthService.refreshToken(); // Info: The firm is stored in the access token and has to be updated when firm is updated.
  },

  removeUser: async (userId: number) => {
    await UserFirmAPI.removeUser(userId);
  },

  updateAdminPermission: async (userId: number, isAdmin: boolean): Promise<User> => {
    const adminPermissionDto = {
      isAdmin: isAdmin,
    } as AdminPermissionDto;

    const userDto = await UserFirmAPI.updateAdminPermission(userId, adminPermissionDto);
    return UserHelper.userFromDto(userDto);
  },

  updateWorkloadAmount: async (userId: number, workload: number): Promise<User>  => {
    const workloadAmountDto = {
      workload: workload,
    } as WorkloadAmountDto;

    const userDto = await UserFirmAPI.updateWorkloadAmount(userId, workloadAmountDto);
    return UserHelper.userFromDto(userDto);
  },
}

export default UserFirmService;