import * as icons from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

type DynamicIconProps = {
  iconName: string;
  fontSize?: SvgIconProps['fontSize'];
};

const DynamicIcon = (props: DynamicIconProps) => {
  const IconComponent = icons[props.iconName as keyof typeof icons];
  if (!IconComponent) {
    return null;
  }

  return <IconComponent fontSize={props.fontSize} />;
};

export default DynamicIcon;
