import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import WorktimePage from './pages/WorktimePage';
import SupportPage from './pages/SupportPage';
import RapportListPage from './pages/RapportListPage';
import ProfilePage from './pages/ProfilePage';
import OverviewPage from './pages/OverviewPage';
import LoginPage from './pages/LoginPage';
import RapportPage from './pages/RapportPage';
import Tapbar from './components/Tapbar';
import CreatePage from './pages/CreatePage';
import Protected from './components/AuthRoute/Protected';
import { MetaContext } from './components/Provider/MetaContextProvider';
import RegistrationPage from './pages/RegistrationPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Loading from './components/Loading';
import AlertSnackbar from './components/AlertSnackbar';
import { Box, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import FirmPage from './pages/FirmPage';
import EditPage from './pages/EditPage';
import { green, orange, red } from '@mui/material/colors';
import PWAInstallPrompt from './components/PWAInstallPrompt';
import Superuser from './components/AuthRoute/Superuser';
import SecretPage from './pages/SecretPage';
import SettingsPage from './pages/SettingsPage';
import Sidebar from './components/Sidebar';

declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
}

const AppContent: React.FC = () => {
  const context = useContext(MetaContext);
  const isXsOrSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, height: '100%', width: '100%', }}>
      {!isXsOrSm && (<Sidebar />)}
      <Box sx={{ flex: 1, overflowY: 'auto', paddingBottom: { xs: 8, lg: 0 } }} >
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/firm" element={<Protected component={FirmPage} />} />
          <Route path="/rapport" element={<Protected component={CreatePage} />} />
          <Route path="/rapport/:id" element={<Protected component={RapportPage} />} />
          <Route path="/rapport/:id/edit" element={<Protected component={EditPage} />} />
          <Route path="/overview" element={<Protected component={OverviewPage} />} />
          <Route path="/profile" element={<Protected component={ProfilePage} />} />
          <Route path="/list" element={<Protected component={RapportListPage} />} />
          <Route path="/time" element={<Protected component={WorktimePage} />} />
          <Route path="/support" element={<Protected component={SupportPage} />} />
          <Route path="/settings" element={<Protected component={SettingsPage} />} />
          <Route path="/secret" element={<Superuser component={SecretPage} />} />
        </Routes>
        {context.isAuthenticated && isXsOrSm && <Tapbar />}
        <AlertSnackbar />
        {isXsOrSm && <PWAInstallPrompt />}
        <Loading />
      </Box>
    </Box>
  );
};

const App = () => {
  const lightTheme = createTheme({
    palette: {
      primary: {
        main: orange[500],
        lighter: orange[200],
        light: orange[300],
        dark: orange[700],
        darker: orange[900],
      },
      success: {
        main: green[500],
        lighter: green[200],
        darker: green[900],
      },
      error: {
        main: red[500],
        lighter: red[200],
        darker: red[900],
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      //mode: 'dark',
      primary: {
        main: "#B17CE6",
        lighter: "#EFE4FA",
        light: "#E2CEF6",
        dark: "#8B3CDA",
        darker: "#964CDE",
      }
    },
  });

  return (
    <ThemeProvider theme={/* Todo: also change in css file*/ false ? darkTheme : lightTheme}>
      <AppContent />
    </ThemeProvider>
  );
};

export default App;
