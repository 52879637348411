type WorktimeType = "work" | "compensation" | "vacation" | "unpaid" | "sickness" | "holiday";
type WorktimeDropdownOptions = { key: WorktimeType, display: string, iconName: string };

export const worktimeOptions: WorktimeDropdownOptions[] = [
  { key: "work", display: "Arbeit", iconName: "Build" },
  { key: "compensation", display: "Kompensation", iconName: "Hail" },
  { key: "vacation", display: "Ferien", iconName: "BeachAccess" },
  { key: "unpaid", display: "Unbezahlt", iconName: "TimeToLeave" },
  { key: "sickness", display: "Absenz / Krankheit", iconName: "Hotel" },
  { key: "holiday", display: "Feiertag", iconName: "Celebration"},
];

export default WorktimeType;