import { FormControlLabel, FormGroup, Stack, Checkbox } from '@mui/material';
import { useContext } from 'react';
import { MetaContext } from '../Provider/MetaContextProvider';
import { RapportContext } from '../Provider/RapportContextProvider';
import { SchochStack } from '../../styles/styles';
import { Job } from '../../models/job';

const jobLabels = [
  "Miele Waschmaschine",
  "Schemaunterlagen ergänzt",
  "Beschriftung / Bezeichnung",
  "Material bestellen / bereitstellen",
  "Störungsquelle gesucht",
  "Defekte Bauteile gesucht",
  "Neues Bauteil eingebaut",
  "Thermorelais eingebaut",
  "Drehrichtungskontrolle",
  "Funktionskontrolle",
  "Isolationsmessung",
  "Inbetriebnahme",
  "Einzug",
  "Instruktion",
  "Fremdmaterial",
  "Messung",
];

const jobFieldMapping: { [label: string]: keyof Job } = {
  "Miele Waschmaschine": "mieleWaschmaschine",
  "Schemaunterlagen ergänzt": "schemaunterlagenErgänzt",
  "Beschriftung / Bezeichnung": "beschriftung",
  "Material bestellen / bereitstellen": "materialBestellen",
  "Störungsquelle gesucht": "stoerungsquelleGesucht",
  "Defekte Bauteile gesucht": "defekteBauteile",
  "Neues Bauteil eingebaut": "neuesBauteil",
  "Thermorelais eingebaut": "thermorelaisEingebaut",
  "Drehrichtungskontrolle": "drehrichtungskontrolle",
  "Funktionskontrolle": "funktionskontrolle",
  "Isolationsmessung": "isolationsmessung",
  "Inbetriebnahme": "inbetriebnahme",
  "Einzug": "einzug",
  "Instruktion": "instruktion",
  "Fremdmaterial": "fremdmaterial",
  "Messung": "messung",
};

const JobsItem = () => {
  const context = useContext(MetaContext);
  const rapportContext = useContext(RapportContext);

  const handleSwitchChange = (field: keyof Job) => (event: React.ChangeEvent<HTMLInputElement>) => {
    rapportContext.setRapport((prev) => ({
      ...prev,
      jobs: { ...prev.jobs,
        [field]: event.target.checked,
      }
    }));
  };

  return (
    <Stack gap={2} marginTop={2}>
      <SchochStack>
        <FormGroup>
          {jobLabels.map((label) => {
            const field = jobFieldMapping[label];
            return (
              <FormControlLabel
                key={label}
                control={<Checkbox checked={Boolean(rapportContext.rapport.jobs[field])} onChange={handleSwitchChange(field)} />}
                label={label}
              />
            );
          })}
        </FormGroup>
      </SchochStack>
    </Stack>
  );
};

export default JobsItem;
