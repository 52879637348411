import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type DialogProps = {
    title: string;
    description: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const DeleteDialog = (props: DialogProps) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogTitle>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{paddingBottom: 2, paddingRight: 2}}>
        <Button variant='outlined' onClick={props.onClose} color="error">Abbrechen</Button>
        <Button variant='contained' onClick={props.onConfirm} autoFocus color="error">Löschen</Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConfirmDialog = (props: DialogProps) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogTitle>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{paddingBottom: 2, paddingRight: 2}}>
        <Button variant='outlined' onClick={props.onClose}>Abbrechen</Button>
        <Button variant='contained' onClick={props.onConfirm} autoFocus>Bestätigen</Button>
      </DialogActions>
    </Dialog>
  );
};