import { Navigate } from "react-router-dom";
import LocalStorageHelper from "../../helpers/local-storage-helper";

type ProtectedProps = {
    component: React.ComponentType<any>;
  };

const Protected = (props: ProtectedProps) => {  
    if (!LocalStorageHelper.isAuthenticated()) {
      return (<Navigate to="/login" />);
    }
  
    return (
        <props.component />
    );
  };

  export default Protected;