import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Contextmenu } from '../../styles/styles';
import FaceIcon from '@mui/icons-material/Face';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { User } from '../../models/user';

type ContextmenuProps = {
  user: User | null;
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  openDeleteDialog: (id: number) => void;
  toggleAdminPermission: (user: User) => void;
  onClose: () => void;
};

const AllMembersContextmenu = (props: ContextmenuProps) => {

  return (
    <Contextmenu
      anchorEl={props.anchorEl}
      open={props.isOpen}
      onClose={props.onClose}
      onClick={props.onClose}
    >
      <MenuItem onClick={() => props.toggleAdminPermission(props.user!)}>
        <ListItemIcon>
          <FaceIcon fontSize="small" />
        </ListItemIcon>
        {props.user?.isAdmin ? "Admin-Rechte entziehen" : "Zum Admin befördern"}
      </MenuItem>
      <MenuItem onClick={() => props.openDeleteDialog(props.user!.id)}>
        <ListItemIcon>
          <PersonRemoveIcon fontSize="small" /> 
        </ListItemIcon>
        Entfernen
      </MenuItem>
    </Contextmenu>
  );
}

export default AllMembersContextmenu;