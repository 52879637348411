import { createContext, useState, ReactNode, useEffect, SetStateAction, Dispatch, useContext } from "react";
import UserService from "../../services/user-service";
import { User } from "../../models/user";
import { Firm } from "../../models/firm";
import UserFirmService from "../../services/user-firm-service";
import { MetaContext } from "./MetaContextProvider";
import { CatalogType } from "../../models/catalog-type";
import FirmService from "../../services/firm-service";

type MeContextType = {
  currentUser: User | null,
  setCurrentUser: Dispatch<SetStateAction<User | null>>,
  currentFirm: Firm | null,
  setCurrentFirm: Dispatch<SetStateAction<Firm | null>>,
  syncCatalogLicences: () => Promise<void>,
  currentCatalog: CatalogType | "",
  setCurrentCatalog: Dispatch<SetStateAction<CatalogType | "">>,
  userLicence: string | null,
  setUserLicence: Dispatch<SetStateAction<string | null>>,
  firmUsers: User[] | null,
  setFirmUsers: Dispatch<SetStateAction<User[] | null>>,
  reset: () => void, 
};

export const MeContext = createContext<MeContextType>({
  currentUser: null,
  setCurrentUser: () => {},
  currentFirm: null,
  setCurrentFirm: () => {},
  syncCatalogLicences: async () => {},
  currentCatalog: "",
  setCurrentCatalog: () => {},
  userLicence: null,
  setUserLicence: () => {},
  firmUsers: null,
  setFirmUsers: () => {},
  reset: () => {},
});

type MeContextProviderProps = {
  children: ReactNode;
};

const MeContextProvider = (props: MeContextProviderProps) => {
  const context = useContext(MetaContext);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentFirm, setCurrentFirm] = useState<Firm | null>(null);
  const [currentCatalog, setCurrentCatalog] = useState<CatalogType | "">("");
  const [userLicence, setUserLicence] = useState<string | null>(null);
  const [firmUsers, setFirmUsers] = useState<User[] | null>(null);

  const fetchCurrentUser = async () => {
    const user = await context.handleAsyncOperation(
      UserService.getCurrentUser
    );

    if (!!user) {
      setCurrentUser(user);
    }
  };

  const fetchCurrentFirm = async (): Promise<Firm | null> => {   
    const firm = await context.handleAsyncOperation(
      UserFirmService.getFirmOfUser
    );

    if (!!firm) {
      setCurrentFirm(firm);
    }

    return firm;
  };

  const fetchUserLicence = async (): Promise<string | null> => {
    const userLicence = await context.handleAsyncOperation(
      UserFirmService.getUserLicence
    );

    !!userLicence ? setUserLicence(userLicence) : setUserLicence(null);
    return userLicence;
  };

  const fetchUsersOfFirm = async () => {
    const firmUsers = await context.handleAsyncOperation(
      UserFirmService.getAllUsers
    );

    if (!!firmUsers) {
      setFirmUsers(firmUsers);
    }
  };

  const syncCatalogLicences = async () => {
    const data = await context.handleAsyncOperation(
      FirmService.syncCatalogLicences
    );

    if (!!data) {
      setCurrentFirm(data);
      context.setAlertMessage("Die Lizenzen wurden erfolgreich geprüft.");
      context.setAlertSeverity("success");
    }
  };

  const checkConnection = async (firmLicence: string, userLicence: string) => {
    const validConnection = await context.handleAsyncOperation(
      () => UserFirmService.checkValidConnectionQuarantine(firmLicence, userLicence)
    );

    if (!validConnection) {
      reset();
    }
  };

  const reset = () => {
    setCurrentUser(null);
    setCurrentFirm(null);
    setUserLicence(null);
    setCurrentCatalog("");
  };

  const initialize = async () => {
    const [_a, firm, userLicence, _b] = await Promise.all([
      fetchCurrentUser(),
      fetchCurrentFirm(),
      fetchUserLicence(),
      fetchUsersOfFirm(),
    ]);

    if (firm !== null && userLicence !== null) {
      await checkConnection(firm.firmLicence, userLicence);
    }
  };

  useEffect(() => {
    if (context.isAuthenticated) {
      initialize();
    }

  }, [context.isAuthenticated]);

  return (
    <MeContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentFirm,
        setCurrentFirm,
        syncCatalogLicences,
        currentCatalog,
        setCurrentCatalog,
        userLicence,
        setUserLicence,
        firmUsers,
        setFirmUsers,
        reset,
      }}
    >
      {props.children}
    </MeContext.Provider>
  );
}

export default MeContextProvider;
