import { useContext, useState } from "react";
import { FormControlLabel, Stack, Switch, } from "@mui/material";
import Titlebar from "../components/Titlebar";
import { SchochStack } from "../styles/styles";
import { MetaContext } from "../components/Provider/MetaContextProvider";

const SettingsPage: React.FC = () => {
  const context = useContext(MetaContext);
  const [checked, setChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    context.setDarkTheme(event.target.checked);
  };

  return (
    <Stack>
      <Titlebar title="Einstellungen" iconName="Settings" />
      <Stack sx={{ padding: { xs: 0, sm: 2 }, }} >
        <SchochStack>
          <FormControlLabel control={<Switch checked={checked} onChange={handleChange} disabled />} label="Dunkelmodus (folgt)" />
        </SchochStack>
      </Stack>
    </Stack>
  );
};

export default SettingsPage;