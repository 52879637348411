import { CommentDto } from "../dto/comment-dto";
import { CreateUpdateRapportDto } from "../dto/create-update-rapport-dto";
import { PostItDto } from "../dto/post-it-dto";
import { RapportDto } from "../dto/rapport-dto";
import authorizedApi from "./api";

const RapportAPI = {
  syncQuarantine: async (): Promise<string[]> => {
    const response = await authorizedApi.get(`/rapports/sync`);
    return response.data as string[];
  },

  exportQuarantine: async (id: string) => {
    await authorizedApi.get(`/rapports/${id}/export`);
  },

  sendPostItQuarantine: async (id: string, postIt: PostItDto): Promise<boolean> => {
    const response = await authorizedApi.post(`/rapports/${id}/post-it`, postIt);
    return response.data as boolean;
  },

  getRapports: async (page?: number, pageSize?: number, search?: string, jobDone?: boolean, billed?: boolean, paperbin?: boolean, archive?: boolean, assignedToMe?: boolean): Promise<RapportDto[]> => {
    const params: Record<string, any> = {
      page,
      pageSize,
      search,
      jobDone,
      billed,
      paperbin,
      archive,
      assignedToMe,
    };
  
    const response = await authorizedApi.get('/rapports', { params });
    return response.data as RapportDto[];
  },

  getRapport: async (id: string): Promise<RapportDto> => {
    const response = await authorizedApi.get(`/rapports/${id}`);
    return response.data as RapportDto;
  },

  createRapport: async (rapport: CreateUpdateRapportDto): Promise<string> => {
    const response = await authorizedApi.post(`/rapports/create`, rapport);
    return response.data;
  },

  copyRapport: async (id: string): Promise<RapportDto> => {
    const response = await authorizedApi.post(`/rapports/${id}/copy`);
    return response.data as RapportDto;
  },

  updateRapport: async (id: string, rapport: CreateUpdateRapportDto): Promise<string> => {
    const response = await authorizedApi.put(`/rapports/${id}`, rapport);
    return response.data;
  },

  deleteRapport: async (id: string) => {
    await authorizedApi.delete(`/rapports/${id}`);
  },

  getRapportCount: async (): Promise<number> => {
    const response = await authorizedApi.get(`/rapports/count`);
    return response.data;
  },

  toggleRapportJobDone: async (id: string): Promise<RapportDto> => {
    const response = await authorizedApi.post(`/rapports/${id}/job-done`);
    return response.data as RapportDto;
  },

  toggleRapportBilled: async (id: string): Promise<RapportDto> => {
    const response = await authorizedApi.post(`/rapports/${id}/billed`);
    return response.data as RapportDto;
  },

  toggleRapportDeleted: async (id: string): Promise<RapportDto> => {
    const response = await authorizedApi.post(`/rapports/${id}/paper-bin`);
    return response.data as RapportDto;
  },

  toggleRapportArchived: async (id: string): Promise<RapportDto> => {
    const response = await authorizedApi.post(`/rapports/${id}/archive`);
    return response.data as RapportDto;
  },

  updateComment: async (id: string, commentDto: CommentDto): Promise<number> => {
    const response = await authorizedApi.put(`/rapports/${id}/comment`, commentDto);
    return response.data as number;
  },
}

export default RapportAPI;