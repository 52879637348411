import { AttachmentDto } from "../dto/attachment-dto";
import { Attachment } from "../models/attachment";
import { FormInfo } from "../models/form-info";
import { base64ToBlob } from "./utils";

const attachmentForms: FormInfo[] = [
  { id: 1, path: '/pdfs/batisec-formular.pdf', name: 'BATISEC-Formular', icon: 'Summarize' },
  { id: 2, path: '/pdfs/Sicherheitsnachweis.pdf', name: 'Sicherheitsnachweis', icon: 'Security' },
  { id: 3, path: '/pdfs/Messprotokoll.pdf', name: 'Mess-/ Prüfprotokoll', icon: 'SquareFoot' },
  { id: 4, path: '/pdfs/Erstprüfung.pdf', name: 'Checkliste Erstprüfung', icon: 'Checklist' },
];

const AttachmentHelper = {
  getAllForms: (): FormInfo[] => {
    return attachmentForms;
  },

  filesToFormData: (files: File[]): FormData => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`attachments`, file);
    });

    return formData;
  },

  attachmentFromDto: (attachmentDto: AttachmentDto): Attachment => {
    return {
      id: attachmentDto.id,
      rapportId: attachmentDto.rapportId,
      filename: attachmentDto.filename,
      fileBlob: base64ToBlob(attachmentDto.fileBlob, attachmentDto.contentType),
      file: null,
    } 
  },
}

export default AttachmentHelper;