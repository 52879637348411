import { Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MeContext } from "../Provider/MeContextProvider";
import UserService from "../../services/user-service";
import { Typography } from "@mui/material";

type SuperuserProps = {
  component: React.ComponentType<any>;
};

const Superuser: React.FC<SuperuserProps> = ({ component: Component }) => {
  const [user, setUser] = useState<{ username: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await UserService.getCurrentUser();
        if (currentUser.username !== 'Superuser') { // Todo RSR-094: Handle in BE
          setRedirect(true);
        } else {
          setUser(currentUser);
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
        setRedirect(false);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (loading) {
    return <Typography>Verifizierung am Gange...</Typography>;
  }

  if (redirect) {
    return <Navigate to='/login' />;
  }

  return user ? <Component /> : null;
};

export default Superuser;