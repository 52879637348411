import { ToggleButton, ToggleButtonProps, useTheme } from "@mui/material";
import { ReactNode } from "react";

type SchochToggleButtonProps = {
  icon: ReactNode;
  text: string;
} & ToggleButtonProps;

const SchochToggleButton = (props: SchochToggleButtonProps) => {  
  const theme = useTheme();

  const xsButton = () => {
    return (
      <ToggleButton
        color="primary"
        sx={{
          [theme.breakpoints.not('xs')]: {
            display: 'none',
          },
        }}
        {...props}
      >{props.icon}</ToggleButton>
    );
  };

  const smButton = () => {
    return (
      <ToggleButton
        color="primary"
        sx={{
          [theme.breakpoints.only('xs')]: {
            display: 'none',
          },
        }}
        {...props}
      >{props.icon} {props.text}</ToggleButton>
    );
  };

  return (
    <>
      {xsButton()}
      {smButton()}
    </>
  );
};

export default SchochToggleButton;
