import { Button, ButtonProps, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";

type SchochButtonProps = {
  icon: ReactNode,
  text: string,
  variant?: "text" | "outlined" | "contained",
} & ButtonProps;

const SchochButton = (props: SchochButtonProps) => {
  const { icon, text, variant, ...buttonParams } = props;
  
  const theme = useTheme();

  const xsButton = () => {
    return (
      <IconButton
        color='primary'
        sx={{
          [theme.breakpoints.up('sm')]: {
            display: 'none',
          },
          border: 1,
          borderRadius: 1,
          borderColor: theme => theme.palette.grey[400],
        }}
        {...buttonParams}
      >
        {icon}
      </IconButton>
    );
  };

  const smButton = () => {
    return (
      <Button
        variant={variant}
        sx={{
          [theme.breakpoints.only('xs')]: {
            display: 'none',
          },
          border: 1,
          borderRadius: 1,
          width: 90,
          padding: (theme) => theme.spacing(0.4),
          boxShadow: 0,
          background: variant === 'contained' ? (theme) => theme.palette.primary.main : (theme) => theme.palette.background.paper,
          ...(variant !== 'contained' && {
            '&:hover': {
              background: (theme) => theme.palette.grey[200],
            },
          }),
        }}
        {...buttonParams}
      >
        <Stack direction="column" alignItems="center">
          {icon}
          <Typography variant="button">{text}</Typography>
        </Stack>
      </Button>
    );
  };

  return (
    <>
      {xsButton()}
      {smButton()}
    </>
  );
};

export default SchochButton;
