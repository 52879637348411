import MenuItem from '@mui/material/MenuItem';
import { PopoverPosition } from '@mui/material';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { MetaContext } from '../Provider/MetaContextProvider';
import { Contextmenu } from '../../styles/styles';
import { Rapport } from '../../models/rapport';
import RapportService from '../../services/rapport-service';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ListItemIcon from '@mui/material/ListItemIcon';
import { DeleteDialog } from '../Dialogs/Dialog';
import PaidIcon from '@mui/icons-material/Paid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import PostItDialog from '../Dialogs/PostItDialog';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

type ContextmenuProps = {
  rapport: Rapport | null;
  setRapports: Dispatch<SetStateAction<Rapport[]>>;
  toggleJobDone: () => void;
  toggleBilled: () => void;
  copyRapport: () => void;
  anchorEl: PopoverPosition | null;
  isOpen: boolean;
  onClose: () => void;
};

const RapportListContextmenu = (props: ContextmenuProps) => {
  const context = useContext(MetaContext);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [postItDialogOpen, setPostItDialogOpen] = useState(false);

  const exportRapport = async () => {
      try {
        context.setIsLoading(true);
        await RapportService.exportQuarantine(props.rapport?.id!);
        await RapportService.toggleRapportArchived(props.rapport?.id!);
        props.setRapports((rapports) => rapports.filter((rapport) => rapport.id !== props.rapport?.id!));
        context.setAlertMessage("Der Rapport wurde erfolgreich exportiert und archiviert.");
        context.setAlertSeverity("success");
      } catch (error) {
        context.setAlertMessage("Beim Exportieren ist ein Fehler aufgetreten.");
        context.setAlertSeverity("error");
        console.error(error);
      } finally {
        context.setIsLoading(false);
      }
  };

  const deleteRapport = async () => {
    try {
      context.setIsLoading(true);
      await RapportService.toggleRapportDeleted(props.rapport?.id!);
      props.setRapports((rapports) => rapports.filter((rapport) => rapport.id !== props.rapport?.id!));
      context.setAlertMessage("Der Rapport wurde in den Papierkorb verschoben.");
      context.setAlertSeverity("success");
    } catch (error) {
      context.setAlertMessage("Beim Löschen ist ein Fehler aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  return (
    <Contextmenu
      anchorReference='anchorPosition'
      anchorPosition={props.anchorEl!}
      open={props.isOpen}
      onClose={props.onClose}
    >
      {props.rapport?.receivedConfirmation === false && <MenuItem sx={{ display: { sm: 'none' } }} onClick={() => setPostItDialogOpen(true)}>
        <ListItemIcon>
          <SendIcon fontSize="small" />
        </ListItemIcon>
        Empfangsbestätigung senden
      </MenuItem>}
      <MenuItem sx={{ display: { sm: 'none' } }} onClick={props.toggleJobDone}>
        <ListItemIcon>
          <HomeRepairServiceIcon fontSize="small" /> 
        </ListItemIcon>
        {props.rapport?.jobDone ? "Markieren als 'Arbeit nicht erledigt'" : "Markieren als 'Arbeit erledigt'"}
      </MenuItem>
      <MenuItem sx={{ display: { sm: 'none' } }} onClick={props.toggleBilled}>
        <ListItemIcon>
          <PaidIcon fontSize="small" /> 
        </ListItemIcon>
        {props.rapport?.billed ? "Markieren als 'Nicht verrechnet'" : "Markieren als 'Verrechnet'"}
      </MenuItem>
      <MenuItem onClick={() => { props.copyRapport(); props.onClose(); }}>
        <ListItemIcon>
          <ContentCopyIcon fontSize='small' />
        </ListItemIcon>
        Kopieren
      </MenuItem>
      <MenuItem onClick={exportRapport}>
        <ListItemIcon>
          <FileUploadIcon fontSize="small" /> 
        </ListItemIcon>
        Export zu SchochAUFTRAG
      </MenuItem>
      <MenuItem onClick={() => setDeleteDialogOpen(true)}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" /> 
        </ListItemIcon>
        Löschen
      </MenuItem>
      <PostItDialog rapport={props.rapport!} setRapports={props.setRapports} isOpen={postItDialogOpen} onClose={() => setPostItDialogOpen(false)} />
      <DeleteDialog title="In Papierkorb verschieben" description="Wollen Sie den ausgewählten Rapport in den Papierkorb verschieben?" isOpen={deleteDialogOpen} onClose={() => {setDeleteDialogOpen(false); props.onClose();}} onConfirm={deleteRapport} />
    </Contextmenu>
  );
}

export default RapportListContextmenu;