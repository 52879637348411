export type Job = {
  id: number;
  mieleWaschmaschine: boolean;
  schemaunterlagenErgänzt: boolean;
  beschriftung: boolean;
  materialBestellen: boolean;
  stoerungsquelleGesucht: boolean;
  defekteBauteile: boolean;
  neuesBauteil: boolean;
  thermorelaisEingebaut: boolean;
  drehrichtungskontrolle: boolean;
  funktionskontrolle: boolean;
  isolationsmessung: boolean;
  inbetriebnahme: boolean;
  einzug: boolean;
  instruktion: boolean;
  fremdmaterial: boolean;
  messung: boolean;
};

export const defaultJob: Job = {
  id: 0,
  mieleWaschmaschine: false,
  schemaunterlagenErgänzt: false,
  beschriftung: false,
  materialBestellen: false,
  stoerungsquelleGesucht: false,
  defekteBauteile: false,
  neuesBauteil: false,
  thermorelaisEingebaut: false,
  drehrichtungskontrolle: false,
  funktionskontrolle: false,
  isolationsmessung: false,
  inbetriebnahme: false,
  einzug: false,
  instruktion: false,
  fremdmaterial: false,
  messung: false,
};