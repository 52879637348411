export type Measurements = {
  id: number;
  fi: string;
  nin: string;
  tnS: string;
  tnC: string;
  tnCS: string;
  counter: string;
  inA: string;
  rIso: string;
  ikA: string;
  mOhm: string;
};

export const defaultMeasurements: Measurements = {
  id: 0,
  fi: '',
  nin: '',
  tnS: '',
  tnC: '',
  tnCS: '',
  counter: '',
  inA: '',
  rIso: '',
  ikA: '',
  mOhm: '',
};