import { Box, Button, IconButton, List, ListItemButton, Skeleton, Stack, Theme, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MeContext } from "./Provider/MeContextProvider";
import { RapportContext } from "./Provider/RapportContextProvider";
import { Material } from "../models/material";
import { MetaContext } from "./Provider/MetaContextProvider";
import MaterialService from "../services/material-service";
import { Npk } from "../models/npk";
import SvgComponent from "../svg/SvgComponent";

type NpkCatalogProps = {
  isXSScreen: boolean;
  searchValue: string;
};

const NpkCatalog = (props: NpkCatalogProps) => {
  const context = useContext(MetaContext);
  const rapportContext = useContext(RapportContext);
  const meContext = useContext(MeContext);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [allMaterial, setAllMaterial] = useState<Npk[]>([]);
  const [loadedMaterial, setLoadedMaterial] = useState<Record<string, Npk[]>>({});
  const [isLoading, setIsLoading] = useState(false);

  const catalogType = 'npk';
  
  const fetchNpkMaterial = async () => {
    const key = Object.keys(loadedMaterial).find(key => key === `${currentIndex}`)
    if (!!key) {
      setAllMaterial(loadedMaterial[`${currentIndex}`]);
      return;
    }

    const data = await context.handleAsyncOperation(
      () => MaterialService.getNpkMaterial(!!currentIndex ? currentIndex : undefined)
    );

    if (!!data) {
      setAllMaterial(data);
      setLoadedMaterial(prev => ({ ...prev, [currentIndex ?? 'null']: data }));
    }
  };

  const fetchSearchedMaterial = async (search?: string) => {
    const data = await context.handleAsyncOperation(
      () => MaterialService.searchNpkMaterial(undefined, 50, search)
    );

    if (!!data) {
      setAllMaterial(data);
    }
  };

  const searchMaterial = useCallback(async (value?: string) => {
    if (!!value) {
      await fetchSearchedMaterial(value);
    } else {
      fetchNpkMaterial();
    }
  }, [props.searchValue]);

  const handleEntryClick = (material: Npk) => {
    if (!material.isMaterial) {
      setCurrentIndex(material.id);
    }
  };

  const formatId = (id: number) => {
    const idString = id.toString();
    if (idString.length !== 9) {
      return idString;
    }
  
    return `${idString.slice(0, 3)} ${idString.slice(3, 6)} ${idString.slice(6, 9)}`;
  };

  const handleMaterialClick = (evt: React.MouseEvent<HTMLElement>, material: Npk) => {
    evt.stopPropagation();
    if (material.isMaterial) {
      const newMaterial: Material = { id: 0, catalogId: material.id, displayedProductId: material.id.toString(), catalogType: catalogType, name: material.name, ic: '', amount: 0, price: 0, }
      rapportContext.setRapport(prev => ({...prev, materials: [ ...prev.materials, newMaterial ] }));
    }
  };

  const initialize = async () => {
    if (meContext.currentFirm?.hasNpk) {
      setIsLoading(true);
      await fetchNpkMaterial();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [currentIndex, props.isXSScreen]);

  useEffect(() => {
    searchMaterial(props.searchValue);
  }, [props.searchValue]);
  
  const displayEntry = useCallback((steiger: Npk) => {
    const isParent = steiger.id === currentIndex;
    console.log(steiger.id, isParent)

    return (
      <Stack>
        <ListItemButton
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            border: 1,
            borderBottom: isParent ? 2 : 1,
            background: isParent ? theme => theme.palette.grey[400] : 'inherit',
          }}
          onClick={() => handleEntryClick(steiger)}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: isParent ? 0 : 2, alignItems: 'center', flex: 1 }}>
            {isParent && (
              <IconButton onClick={(event) => { setCurrentIndex(steiger.parentId); event.stopPropagation(); }}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
            {steiger.isMaterial && (
              <Button onClick={(event) => handleMaterialClick(event, steiger)} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: props.isXSScreen ? 'column' : 'row', gap: props.isXSScreen ? 0 : 0.75 }}>
                  <Typography variant='button'>{formatId(steiger.id)}</Typography>
                  <Typography variant='button'>hinzufügen</Typography>
                </Box>
              </Button>
            )}
            <Typography
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                flex: 1,
                width:  0,
              }}
            >
              {steiger.name !== "" ? steiger.name : "(Kein Name)"}
            </Typography>
            {steiger.svgData && <SvgComponent svgData={steiger.svgData} />}
          </Box>
        </ListItemButton>
        {isParent && (
          <List sx={{ paddingTop: 0 }}>
            {steiger.children?.map((item, index) => (
              <Box key={index}>
                {displayEntry(item)}
              </Box>
            ))}
          </List>
        )}
      </Stack>
    );
  }, [currentIndex]);

  return (
    <Box>
      {isLoading ?
        <Stack gap={0.5}>
          {[...Array(2)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={53} />
          ))}
        </Stack> :
        <Stack>
          {allMaterial.map((item, index) =>
            <Box key={index}>
              {displayEntry(item)}
            </Box>
          )}
        </Stack>
      }
    </Box>
  );
};

export default NpkCatalog;