import { Alert, Chip, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from "@mui/material";
import { User } from "../models/user";
import React, { useContext, useEffect, useState } from "react";
import { ConfirmDialog } from "./Dialogs/Dialog";
import FaceIcon from '@mui/icons-material/Face';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AllMembersContextmenu from "./Contextmenu/AllMembersContextmenu";
import { MeContext } from "./Provider/MeContextProvider";
import { ConditionalTableCell } from "../styles/styles";

type MemberTableProps = {
  users: User[];
  removeUser: (id: number) => Promise<void>;
  toggleAdminPermission: (user: User) => Promise<void>;
};

const MemberTable = (props: MemberTableProps) => {
  const meContext = useContext(MeContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, user: User) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentUser(null);
  };

  useEffect(() => {}, [meContext.currentUser]);

  return (
    <Stack>
      {props.users.length !== 0 ?
      <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
        <Table sx={{ border: 1, borderColor: 'black' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme => theme.palette.grey[400], borderBottom: 2, }}>
              <TableCell>Benutzername</TableCell>
              <ConditionalTableCell>Vorname</ConditionalTableCell>
              <ConditionalTableCell>Nachname</ConditionalTableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{props.users.map((user) =>
            <TableRow
              key={user.username}
              sx={{
                '&:last-child td, &:last-child th': { border: 0, borderColor: theme => theme.palette.grey[400] },
                '&:nth-of-type(even)': { backgroundColor: theme => theme.palette.grey[50] },
                '&:nth-of-type(even), &:nth-of-type(odd)': { backgroundColor: theme => (meContext.currentUser?.id === user.id) ? theme.palette.primary.lighter : '' },
              }}
            >
              <TableCell>{user.username}</TableCell>
              <ConditionalTableCell>{user.firstName}</ConditionalTableCell>
              <ConditionalTableCell>{user.lastName}</ConditionalTableCell>
              <TableCell>{user.isAdmin && <Chip icon={<FaceIcon />} size='small' label="Admin" variant="filled" />}</TableCell>
              <TableCell align="right">
                {(meContext.currentUser?.isAdmin && meContext.currentUser.id !== user.id) && <IconButton sx={{padding: theme => theme.spacing(0)}} onClick={(event) => handleMenuClick(event, user)}><MoreHorizIcon /></IconButton>}
              </TableCell>
              <ConfirmDialog
                title="Benutzer entfernen"
                description={`Wollen Sie '${user.firstName} ${user.lastName}' aus der Firma entfernen?`}
                isOpen={deleteId === user.id}
                onClose={() => setDeleteId(null)}
                onConfirm={() => {setDeleteId(null); props.removeUser(user.id)}}
              />
            </TableRow>
          )}
          </TableBody>
        </Table>
      </TableContainer> :
      <Alert severity='info' variant="outlined" sx={{ flex: 1, margin: { xs: 2, sm: 0, } }}>Keine Ergebnisse gefunden</Alert>}
      <AllMembersContextmenu user={currentUser!} anchorEl={anchorEl} openDeleteDialog={setDeleteId} toggleAdminPermission={props.toggleAdminPermission} isOpen={!!anchorEl} onClose={handleMenuClose} />
    </Stack>
  );
}

export default MemberTable;