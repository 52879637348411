import { AddressDto } from "../dto/address-dto";
import { Address } from "../models/address";

const AddressHelper = {
  addressFromDto: (addressDto: AddressDto): Address => {
    return {
      id: addressDto.id,
      firstname: addressDto.firstname,
      lastname: addressDto.lastname,
      street: addressDto.street,
      postalCode: addressDto.postalCode,
      canton: addressDto.canton,
      city: addressDto.city,
      country: addressDto.country,
      phone: addressDto.phone,
      mail: addressDto.mail,
    };
  },

  addressToDto: (address: Address): AddressDto => {
    return {
      id: address.id,
      firstname: address.firstname,
      lastname: address.lastname,
      street: address.street,
      postalCode: address.postalCode,
      canton: address.canton,
      city: address.city,
      country: address.country,
      phone: address.phone,
      mail: address.mail,
    };
  },
}

export default AddressHelper;