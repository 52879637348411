import { Alert, Box, Button, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MeContext } from "./Provider/MeContextProvider";
import { RapportContext } from "./Provider/RapportContextProvider";
import { Material } from "../models/material";
import { MetaContext } from "./Provider/MetaContextProvider";
import MaterialService from "../services/material-service";
import { Eldas } from "../models/eldas";

type EldasCatalogProps = {
  isXSScreen: boolean;
  searchValue: string;
};

const EldasCatalog = (props: EldasCatalogProps) => {
  const context = useContext(MetaContext);
  const rapportContext = useContext(RapportContext);
  const meContext = useContext(MeContext);
  const [allMaterial, setAllMaterial] = useState<Eldas[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const catalogType = 'eldas';
  
  const searchEldasMaterial = async () => {
    if (props.searchValue === "") {
      return;
    }

    setIsLoading(true);
    const data = await MaterialService.searchEldasMaterial(undefined, 50, props.searchValue);
    setIsLoading(false);

    if (!!data) {
      setAllMaterial(data);
    }
  };

  const formatId = (id: number) => {
    const idString = id.toString();
    if (idString.length !== 9) {
      return idString;
    }
  
    return `${idString.slice(0, 3)} ${idString.slice(3, 6)} ${idString.slice(6, 9)}`;
  };

  const handleMaterialClick = (eldas: Eldas) => {
    const newMaterial: Material = { id: 0, catalogId: eldas.id, displayedProductId: eldas.id.toString(), catalogType: catalogType, name: eldas.name, ic: '', amount: 0, price: 0, }
    rapportContext.setRapport(prev => ({...prev, materials: [ ...prev.materials, newMaterial ] }));
  };

  useEffect(() => {
    searchEldasMaterial();
  }, [props.searchValue]);

  return (
    <Box>
      {isLoading ?
        <Stack gap={0.5}>
          {[...Array(2)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={53} />
          ))}
        </Stack> :
        <Stack>
          {(allMaterial.length === 0) && <Alert severity="info" sx={{ alignItems: 'center'}}>Suchen Sie nach Begriffen. Falls Sie nach einer Eldas-Nummer suchen möchten, geben Sie die Nummer ohne Leerzeichen ein.</Alert>}
          {allMaterial.map((material, index) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'row', gap: 2, border: 1, padding: 1, alignItems: 'center' }}>
              <Button onClick={() => handleMaterialClick(material)} variant="outlined">
                <Box sx={{ display: 'flex', flexDirection: props.isXSScreen ? 'column' : 'row', gap: props.isXSScreen ? 0 : 0.75 }}>
                  <Typography variant='button'>{formatId(material.id)}</Typography>
                  <Typography variant='button'>hinzufügen</Typography>
                </Box>
              </Button>
              <Tooltip title={`${material.name}, ${material.short}`}>
                <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', flex: 1, width:  0, }}>
                  {material.name}, {material.short}
                </Typography>
              </Tooltip>
            </Box>
          ))}
        </Stack>
      }
    </Box>
  );
};

export default EldasCatalog;