import { createContext, useState, ReactNode, useEffect, useContext, Dispatch, SetStateAction } from "react";
import { MetaContext } from "./MetaContextProvider";
import RapportService from "../../services/rapport-service";
import { useNavigate, useParams } from "react-router-dom";
import { CreateUpdateRapport, defaultCreateUpdateRapport } from "../../models/create-update-rapport";
import { Attachment } from "../../models/attachment";
import AttachmentService from "../../services/attachment-service";

type RapportContextType = {
  rapportId: string;
  rapport: CreateUpdateRapport,
  setRapport: Dispatch<SetStateAction<CreateUpdateRapport>>,
  saveRapport: () => void,
  attachments: Attachment[],
  setAttachments: Dispatch<SetStateAction<Attachment[]>>,
  attachmentsLoaded: boolean,
  setAttachmentsLoaded: Dispatch<SetStateAction<boolean>>,
  isWarning: boolean,
};

export const RapportContext = createContext<RapportContextType>({
  rapportId: '',
  rapport: defaultCreateUpdateRapport,
  setRapport: () => {},
  saveRapport: () => {},
  attachments: [],
  setAttachments: () => {},
  attachmentsLoaded: false,
  setAttachmentsLoaded: () => {},
  isWarning: false,
});

type RapportContextProviderProps = {
    children: ReactNode;
};

const RapportContextProvider = (props: RapportContextProviderProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const context = useContext(MetaContext);
  const [rapport, setRapport] = useState<CreateUpdateRapport>(defaultCreateUpdateRapport);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [attachmentsLoaded, setAttachmentsLoaded] = useState(false);
  const [isWarning, setWarning] = useState<boolean>(false);
  const [rapportId, setRapportId] = useState("");

  const fetchRapport = async () => {
    if (!!!id) {
      return;
    }
    setRapportId(id);

    const data = await context.handleAsyncOperation(
      () => RapportService.getRapportUpdatable(id)
    );

    if (!!data) {
      setRapport(data);
      context.setSelectedRapportIds([id]);
    } else {
      context.setAlertMessage("Daten konnten nicht geladen werden.");
      context.setAlertSeverity("warning");
    }
  };

  const saveRapport = async () => {
    try {
      let rapportId = id;
      context.setIsLoading(true);
      if (!!id) { // Info: if there is no id, the rapport doesn't exist yet
        await RapportService.updateRapport(id, rapport);
      } else {
        rapportId = await RapportService.createRapport(rapport);
        context.setSelectedRapportIds([rapportId]);
      }

      const files: File[] = attachments.filter((attachment): attachment is Attachment & { file: File } => attachment.file !== null).map(x => x.file);
      await AttachmentService.addAttachments(rapportId!, files);
  
      setWarning(false);
      navigate('/list');
    } catch (error) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
    } finally {
      context.setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRapport();
  }, [rapportId]);

  return (
    <RapportContext.Provider value={{
      rapportId,
      rapport,
      setRapport,
      saveRapport,
      attachments,
      setAttachments,
      attachmentsLoaded,
      setAttachmentsLoaded,
      isWarning
    }}>
      {props.children}
    </RapportContext.Provider>
  );
}

export default RapportContextProvider;
