type SchochLogoProps = {
  size: number,
} & any;

const SchochLogo = (props: SchochLogoProps) => {
  const { size, ...svgParams } = props;

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    id="e3HpvJIi9yK1"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    width={size}
    height={size}
    stroke="#000"
    viewBox="0 0 300 300"
    {...svgParams}
  >
    <defs>
      <linearGradient
        id="e3HpvJIi9yK2-fill"
        x1={0.865}
        x2={0.133}
        y1={0.865}
        y2={0.135}
        gradientUnits="objectBoundingBox"
        spreadMethod="pad"
      >
        <stop id="e3HpvJIi9yK2-fill-0" offset="0%" stopColor="#cc6100" />
        <stop id="e3HpvJIi9yK2-fill-1" offset="100%" stopColor="#ff9433" />
      </linearGradient>
    </defs>
    <path
      fill="url(#e3HpvJIi9yK2-fill)"
      strokeWidth={2}
      d="M-103.988-.656C-105.3-80.697-83.65-104.768 0-104.378S104.644-85.29 104.378 0 87.586 103.332 0 104.378-102.676 84.634-103.988-.656Z"
      transform="matrix(1.18067 0 0 1.18067 150 149.983)"
    />
    <text
      fill="#fff"
      stroke="#000"
      x="50%"
      y="55%"
      fontFamily="Roboto"
      fontSize={220}
      fontWeight={900}
      dominantBaseline="middle"
      textAnchor="middle"
    >
      S
    </text>
  </svg>
  );
};

export default SchochLogo;