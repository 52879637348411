import { Box, Button, Paper, Stack, Tooltip, Typography } from "@mui/material";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import EditIcon from '@mui/icons-material/Edit';
import RapportItemHelper from "../helpers/rapport-item-helper";
import ItemEntry from "../models/item-entry";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { RapportContext } from "./Provider/RapportContextProvider";
import { Worktime } from "../models/worktime";
import { formatHours } from "../helpers/utils";

type EditWorktimeProps = {
  setCurrentItem: Dispatch<SetStateAction<ItemEntry | null>>;
};

const EditWorktime = (props: EditWorktimeProps) => {
  const rapportContext = useContext(RapportContext);
  const [infoText, setInfoText] = useState(false);
  const [total, setTotal] = useState(0);
  const [nEntries, setNEntries] = useState(0);
  const [latestEntry, setLatestEntry] = useState<Worktime | null>(null);
  const [nUsers, setNUsers] = useState(0);

  const worktimeItemEntry: ItemEntry = RapportItemHelper.getItemByBeName('worktime')!;

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString('de-DE', { day: '2-digit', month: 'long' });
  };

  useEffect(() => {
    let total_ = 0;
    let errorText = false;
    let first = true;
    rapportContext.rapport.worktimes.sort((a, b) => b.date.getTime() - a.date.getTime()).forEach(worktime => {
      if (first) {
        first = false;
        setLatestEntry(worktime);
      }
      
      total_ += worktime.timeInHours;
      if (worktime.timeInHours === 0 || worktime.user === null) {
        errorText = true;
      }
    });

    const allUsers = rapportContext.rapport.worktimes.flatMap(worktime => worktime.username);
    const uniqueUsers = new Set(allUsers.filter(user => user !== ""));
    const nEntries = rapportContext.rapport.worktimes.length;

    setNUsers(uniqueUsers.size);
    setInfoText(errorText);
    setTotal(total_);
    setNEntries(nEntries);
  }, [rapportContext.rapport.worktimes]);

  return (
    <Stack gap={2}>
      <Box gap={2} display='flex' justifyContent='space-evenly' alignItems='center'>
        <HourglassBottomIcon fontSize="large" />
        <Typography sx={{ flex: 1, textAlign: 'center'}} variant='h6'>Arbeitszeit</Typography>
      </Box>
      <Tooltip title={infoText ? "Einträge sind nicht vollständig" : ""}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
          <Paper sx={{ padding: 2, flex: 1, borderRadius: 0, background: infoText ? theme => theme.palette.primary.lighter : "" }}>
            <Typography textAlign={'center'} variant="subtitle2">Einträge</Typography>
            <Typography textAlign='center' variant="h6">{nEntries}</Typography>
          </Paper>
          <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
            <Typography textAlign={'center'} variant="subtitle2">Mitarbeiter</Typography>
            <Typography textAlign='center' variant="h6">{nUsers}</Typography>
          </Paper>
        </Box>
      </Tooltip>
      <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
        <Typography textAlign={'center'} variant="subtitle2">Letzte Arbeitszeit</Typography>
        <Stack>
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'center' }}>
            {latestEntry !== null ? (latestEntry.username !== "" ? latestEntry.username : '(Kein Name)') : '(Kein Eintrag)'}
          </Typography>
          {latestEntry !== null && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: 1, }}>
            <Typography>{formatDate(latestEntry.date)}</Typography>
            <Typography>{formatHours(latestEntry.timeInHours)}</Typography>
          </Box>}
        </Stack>
      </Paper>
      
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
        <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
          <Typography textAlign={'center'} variant="subtitle2">Totale Arbeitszeit</Typography>
          <Typography textAlign='center' variant="h6">{formatHours(total)}</Typography>
        </Paper>
          <Button variant="contained" sx={{ alignSelf: 'center', padding: 2 }} onClick={() => props.setCurrentItem(worktimeItemEntry)}>
            <EditIcon fontSize="large" />
          </Button>
        </Box>
    </Stack>
  );
};

export default EditWorktime;