import { CommentDto } from "../dto/comment-dto";
import { Comments } from "../models/comment";
import UserHelper from "./user-helper";

const CommentHelper = {
  commentFromDto: (commentDto: CommentDto): Comments => {
    return {
      id: commentDto.id,
      rapportId: commentDto.rapportId,
      commentContent: commentDto.commentContent,
      creator: commentDto.creator !== null ? UserHelper.userFromDto(commentDto.creator) : null,
      createDate: commentDto.createDate !== null ? new Date(commentDto.createDate) : null,
    };
  },

  commentToDto: (comment: Comments): CommentDto => {
    return {
      id: comment.id,
      rapportId: comment.rapportId,
      commentContent: comment.commentContent,
      creator: comment.creator !== null ? UserHelper.userToDto(comment.creator) : null,
      createDate: comment.createDate,
    };
  },
}

export default CommentHelper;