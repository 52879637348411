import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import MetaContextProvider from './components/Provider/MetaContextProvider';
import MeContextProvider from './components/Provider/MeContextProvider';
import * as serviceWorker from './serviceWorkerRegistration';

const domNode = document.getElementById('root') as Element;
const root = createRoot(domNode);
root.render(
  <MetaContextProvider>
    <MeContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MeContextProvider>
  </MetaContextProvider>
);

serviceWorker.register();