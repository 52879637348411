import { Box, Theme, Typography } from "@mui/material";
import { Severity } from "./Provider/MetaContextProvider";

type AbsoluteTextProps = {
  text: string;
  textSeverity: Severity;
};

const AbsoluteText = (props: AbsoluteTextProps) => {
  const textColor = (theme: Theme) => ({
    success: theme.palette.success.main,
    info: theme.palette.info.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
  }[props.textSeverity]);

  return (
    <Box
      sx={{
        position: "absolute",
        pointerEvents: 'none',
        maxHeight: '90vh',
        maxWidth: '90vw',
        width: "auto",
        height: "auto",
        top: '40%',
        left: { xs: '10%', sm: '25%', lg: '40%', },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.5,
        zIndex: 9,
        transform: "rotate(-55deg)",
        transformOrigin: "center",
        color: textColor,
      }}
    >
      <Typography variant="h1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>{props.text}</Typography>
    </Box>
  );
};

export default AbsoluteText;