import { ChangeEvent, SyntheticEvent, useCallback, useContext, useEffect, useState } from "react";
import { Autocomplete, AutocompleteInputChangeReason, Box, Collapse, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { RapportContext } from "./Provider/RapportContextProvider";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AddressAPI from "../api/address-api";
import { Address } from "../models/address";
import AddressService from "../services/address-service";
import { MetaContext } from "./Provider/MetaContextProvider";
import { MeContext } from "./Provider/MeContextProvider";
import debounce from "lodash/debounce";
import AddressDialog from "./Dialogs/AddressDialog";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const EditCustomer = () => {
  const context = useContext(MetaContext);
  const rapportContext = useContext(RapportContext);
  const meContext = useContext(MeContext);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  const titles = ['Kunde', 'Ausführungsort', 'Anschrift'];

  const toggleSearch = async () => {
    const newState = !isSearchOpen;
    setIsSearchOpen(prev => !prev);
    if (newState) {
      const addressBook = await AddressAPI.getAllAddresses(1, 100);
      setAddresses(addressBook);
    }
  };

  const setCustomer = (customer: Address) => {
    const updatedCustomers = [...rapportContext.rapport.customers];
    updatedCustomers[activeStep] = customer;
    
    const updatedRapport = {
      ...rapportContext.rapport,
      customers: updatedCustomers,
    };

    rapportContext.setRapport(updatedRapport);
  };

  const handleAddressSelect = (evt: ChangeEvent<{}>, newValue: string | null) => {
    const selectedAddress = addresses.find((address) => address.firstname + " " + address.lastname === newValue);
    if (!!!selectedAddress) {
      return;
    }

    setCustomer(selectedAddress);
  };

  const updateCustomerProperty = (property: string) => (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    const updatedCustomer = {
      ...rapportContext.rapport.customers[activeStep],
      [property]: newValue,
    };
    setCustomer(updatedCustomer);
  };

  const copyCustomer = () => {
    const copyCustomer = { ...rapportContext.rapport.customers[0] };
    setCustomer(copyCustomer);
  };

  const handleSearchChange = async (_event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      debouncedSearch(value);
    }
  };

  const debouncedSearch = useCallback(debounce(async (searchTerm?: string) => {
    const addressBook = await AddressAPI.getAllAddresses(1, 100, searchTerm);
    setAddresses(addressBook);
  }, 400), []);

  const saveAddress = async (address: Address) => {
    const id = await context.handleAsyncOperation(
      () => AddressService.addAddress(address)
    );

    if (id !== null) {
      context.setAlertMessage('Die Adresse wurde erfolgreich gespeichert.');
      context.setAlertSeverity('success');
    } else {
      context.setAlertMessage("Ein Fehler ist beim Hinzufügen ins Adressbuch aufgetreten.");
      context.setAlertSeverity("error");
    }

    setIsDialogOpen(false);
  };

  return (
    <Stack gap={1} flex={1}>
      <Box display='flex' justifyContent='space-evenly' alignItems='center'>
        <LocationOnIcon fontSize="large" />
        <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">{titles[activeStep]} ({activeStep + 1}/3)</Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent='space-between' gap={2}>
        <Box>
          {activeStep !== 0 &&
          <Tooltip title="Werte von 'Kunde' übernehmen">
            <span>
              <IconButton onClick={copyCustomer}>
                <ContentPasteIcon />
              </IconButton>
            </span>
          </Tooltip>
          }
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='end' flex={1}>
          <Collapse in={isSearchOpen} orientation="horizontal" collapsedSize={0}>
            <Autocomplete
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                flex: 1,
                width: 180,
              }}
              noOptionsText='Keine Treffer'
              options={addresses.map((address) => address.firstname + " " + address.lastname).filter((value, index, self) => self.indexOf(value) === index)}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Firmenadressbuch" />}
              onInputChange={handleSearchChange}
              onChange={handleAddressSelect}
            />
          </Collapse>
          <Tooltip title="Firmenadressbuch durchsuchen">
            <span>
              <IconButton onClick={toggleSearch} disabled={!!!meContext.currentFirm}>
                <SearchIcon />
              </IconButton>
            </span>
          </Tooltip>
          {meContext.currentUser?.isAdmin && <Tooltip title="Dem Firmenadressbuch hinzufügen">
            <span>
              <IconButton onClick={() =>  setIsDialogOpen(true)} disabled={!!!meContext.currentFirm} sx={{ paddingRight: theme => theme.spacing(0), }}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>}
        </Box>
      </Box>
      <Swiper
        modules={[Pagination]}
        slidesPerView={'auto'}
        spaceBetween={16}
        pagination={{ dynamicBullets: true, clickable: true }}
        grabCursor
        onSlideChange={(swiper: any) => setActiveStep(swiper.activeIndex)}
      >
        {rapportContext.rapport.customers.map((customer, index) => (
        <SwiperSlide key={index}>
          <Stack sx={{ gap: 1, paddingBottom: 3, paddingTop: 1 }}>
            <Box display="flex" flexDirection="row" gap={1} flexGrow={1}>
              <TextField
                sx={{width: '50%'}}
                value={customer.firstname}
                label="Vorname"
                onChange={updateCustomerProperty('firstname')}
                size="small"
              />
              <TextField
                sx={{width: '50%'}}
                value={customer.lastname}
                label="Name"
                onChange={updateCustomerProperty('lastname')}
                size="small"
              />
            </Box>
            <TextField
              value={customer.street}
              label="Strasse"
              onChange={updateCustomerProperty('street')}
              size="small"
            />
            <Box gap={1} display="flex" flexDirection="row">
              <TextField
                sx={{width: '25%'}}
                value={customer.postalCode}
                label="PLZ"
                onChange={updateCustomerProperty('postalCode')}
                size="small"
              />
              <TextField
                sx={{flex: 1}}
                value={customer.city}
                label="Ort"
                onChange={updateCustomerProperty('city')}
                size="small"
              />
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
              <TextField
                sx={{width: '50%'}}
                value={customer.canton}
                label="Kanton"
                onChange={updateCustomerProperty('canton')}
                size="small"
              />
              <TextField
                sx={{width: '50%'}}
                value={customer.country}
                label="Land"
                onChange={updateCustomerProperty('country')}
                size="small"
              />
            </Box>
            <TextField
              value={customer.phone}
              label="Telefon-Nr."
              onChange={updateCustomerProperty('phone')}
              size="small"
            />
            <TextField
              value={customer.mail}
              label="Email"
              onChange={updateCustomerProperty('mail')}
              size="small"
            />
          </Stack>
        </SwiperSlide>
        ))}
      </Swiper>
      <AddressDialog address={{ ...rapportContext.rapport.customers[activeStep], id: 0 }} isOpen={isDialogOpen} onSave={saveAddress} onClose={() => setIsDialogOpen(false)} />
    </Stack>
  );
};

export default EditCustomer;