import UserAPI from "../api/user-api";
import { JwtDto } from "../dto/jwt-dto";
import LocalStorageHelper from "../helpers/local-storage-helper";
import UserHelper from "../helpers/user-helper";
import { User } from "../models/user";

const UserService = {
  getCurrentUser: async (): Promise<User> => {
    const userDto = await UserAPI.getCurrentUser();
    return UserHelper.userFromDto(userDto);
  },

  updateUser: async (user: User): Promise<number> => {
    const updateUserDto = UserHelper.userToUpdate(user);
    return await UserAPI.updateUser(updateUserDto);
  },

  deleteUser: async () => {
    await UserAPI.deleteUser();
  },

  usernameExists: async (username: string): Promise<boolean> => {
    return await UserAPI.usernameExists(username);
  },

  register: async (user: User): Promise<number> => {
    const createUserDto = UserHelper.userToCreate(user);
    return await UserAPI.register(createUserDto);
  },

  login: async (user: User, setAuthenticated: (isAuth: boolean) => void) => {
    const credentials = UserHelper.userToCredentials(user);
    const jwt = await UserAPI.login(credentials);
    LocalStorageHelper.storeSessionToken(jwt.accessToken!);
    LocalStorageHelper.storeRefreshToken(jwt.refreshToken!);
    LocalStorageHelper.storeExpiryDate(jwt.refreshExpiryDate!);
    setAuthenticated(true);
  },

  logout: async (setAuthenticated: (isAuth: boolean) => void) => {
    await UserAPI.logout();
    LocalStorageHelper.removeSessionToken();
    LocalStorageHelper.removeRefreshToken();
    LocalStorageHelper.removeExpiryDate();
    LocalStorageHelper.removeRapportFilter();
    setAuthenticated(false);
  },

  refreshToken: async () => {
    const jwtDto: JwtDto = {
      accessToken: LocalStorageHelper.getSessionToken(),
      refreshToken: LocalStorageHelper.getRefreshToken(),
      refreshExpiryDate: LocalStorageHelper.getExpiryDate()
    }

    const jwt = await UserAPI.refreshToken(jwtDto);
    LocalStorageHelper.storeSessionToken(jwt.accessToken!);
  },

  updatePassword: async (user: User): Promise<number> => {
    const credentialsDto = UserHelper.userToCredentials(user);
    return await UserAPI.updatePassword(credentialsDto);
  },

  setDarkMode: (isDarkMode: boolean) => {
    LocalStorageHelper.setDarkMode(isDarkMode);
  },
  
  getDarkMode: (): boolean => {
    return LocalStorageHelper.getDarkMode();
  },
};

export default UserService;