import { Stack, Typography } from "@mui/material";
import Titlebar from "../components/Titlebar";

const SecretPage: React.FC = () => {
  return (
    <Stack>
      <Titlebar title="Superuser" iconName="Face" />
      <Stack>
        <Typography>Hallo</Typography>
      </Stack>
    </Stack>
  );
};

export default SecretPage;