import { AttachmentDto } from "../dto/attachment-dto";
import authorizedApi from "./api";

const AttachmentAPI = {
  getAttachments: async (rapportId: string): Promise<AttachmentDto[]> => {
    const response = await authorizedApi.get(`/attachments/${rapportId}`);
    return response.data as AttachmentDto[];
  },

  addAttachments: async (rapportId: string, attachment: FormData): Promise<number[]> => {
    const config = { headers: { 'Content-Type': 'multipart/form-data'}};
    const response = await authorizedApi.post(`/attachments/${rapportId}`, attachment, config);
    return response.data as number[];
  },

  deleteAttachment: async (id: number) => {
    await authorizedApi.delete(`/attachments/${id}`);
  },
};

export default AttachmentAPI;