type SvgComponentProps = {
  svgData: string;
};

const SvgComponent = (props: SvgComponentProps) => {
  const updatedSvgData = props.svgData
    .replace(/width="([^"]*)mm"/g, (match, p1) => `width="${p1}px"`)
    .replace(/height="([^"]*)mm"/g, (match, p1) => `height="${p1}px"`);

  return (
    <div dangerouslySetInnerHTML={{ __html: updatedSvgData }} />
  );
};

export default SvgComponent;
