import AddressHelper from "../helpers/address-helper";
import { Address } from "../models/address";
import AddressAPI from "../api/address-api";

const AddressService = {
  syncAddressBookQuarantine: async () => {
    await AddressAPI.syncAddressBookQuarantine();
  },

  getAddressCount: async (): Promise<number> => {
    return await AddressAPI.getAddressCount();
  },

  getAllAddresses: async (page?: number, pageSize?: number, searchTerm?: string): Promise<Address[]> => {
    const addressDtos = await AddressAPI.getAllAddresses(page, pageSize, searchTerm);

    return addressDtos.map((addressDto) =>
      AddressHelper.addressFromDto(addressDto)
    );
  },

  getAddress: async (id: number): Promise<Address | null> => {
    const addressDto = await AddressAPI.getAddress(id);
    if (!addressDto) {
      return null;
    }

    return AddressHelper.addressFromDto(addressDto);
  },

  addAddress: async (address: Address): Promise<number> => {
    const addressDto = AddressHelper.addressToDto(address);
    return await AddressAPI.addAddress(addressDto);
  },

  updateAddress: async (id: number, customer: Address): Promise<number> => {
    const addressDto = AddressHelper.addressToDto(customer);
    return await AddressAPI.updateAddress(id, addressDto);
  },

  deleteAddress: async (id: number) => {
    await AddressAPI.deleteAddress(id);
  },
}

export default AddressService;