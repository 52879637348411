import { List, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Avatar, ListItemButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EntryOverview from './Dialogs/EntryOverview';
import { useContext, useState } from 'react';
import { RapportContext } from './Provider/RapportContextProvider';
import DynamicIcon from './DynamicIcon';
import ItemEntry from '../models/item-entry';
import ItemDialog from './Dialogs/ItemDialog';

type RapportEntryListProps = {
  entries: ItemEntry[];
  isEditable: boolean;
};

const RapportEntryList = (props: RapportEntryListProps) => {
  const rapportContext = useContext(RapportContext);
  const [currentItem, setCurrentItem] = useState<ItemEntry | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDeleteItem = (event: React.MouseEvent<HTMLButtonElement>, item: ItemEntry) => {
    event.stopPropagation();
    const index = rapportContext.rapport.entries.findIndex(entry => entry.id === item.id);
  
    if (index !== -1) {
      const updatedEntries = [...rapportContext.rapport.entries];
      updatedEntries[index] = { ...updatedEntries[index], isActive: false };
  
      rapportContext.setRapport({
        ...rapportContext.rapport,
        entries: updatedEntries,
      });
    }
  };

  return (
    <div>
      <List>
        {props.isEditable &&
        <ListItemButton onClick={() => setDialogOpen(true)}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Neues Element hinzufügen" />
        </ListItemButton>}
        {rapportContext.rapport.entries
          .filter(item => item.isActive === true)
          .map((item) => (
        <ListItemButton key={item.id} onClick={() => setCurrentItem(item)}>
          <ListItemAvatar>
            <Avatar
              sx={{ backgroundColor: theme => theme.palette.primary.main }}
              variant='rounded'
            >
              <DynamicIcon iconName={item.iconName} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.name} />
          {props.isEditable && <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={(event) => handleDeleteItem(event, item)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>}
        </ListItemButton>
        ))}
      </List>
      <ItemDialog itemKey={!!currentItem ? currentItem.beName : null} itemName={!!currentItem ? currentItem.name : null} isOpen={currentItem !== null} onClose={() => {setCurrentItem(null)}} />
      <EntryOverview open={dialogOpen} closeDialog={() => setDialogOpen(false)} />
    </div>
  );
};

export default RapportEntryList;
