export type User = {
    id: number;
    profilePicture: Blob | null;
    username: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    color: string | null;
    isAdmin: boolean;
    overtimeInMinutes: number;
    workload: number;
  };
  
  export const defaultUser: User = {
    id: 0,
    profilePicture: null,
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    color: null,
    isAdmin: false,
    overtimeInMinutes: 0,
    workload: 0,
  };
