import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ConnectAccount } from "../../models/connect-account";

type JoinFirmDialogProps = {
  isOpen: boolean;
  connectAccount: ConnectAccount;
  setConnectAccount: Dispatch<SetStateAction<ConnectAccount>>;
  onConfirm: () => void;
  onClose: () => void;
};

const JoinFirmDialog = (props: JoinFirmDialogProps) => {
  const updateFirmLicence = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    props.setConnectAccount(prev => ({ ...prev, firmLicence: newValue }));
  };  

  const updateUserLicence = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    props.setConnectAccount(prev => ({ ...prev, userLicence: newValue }));
  };  

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      sx={{ maxHeight: 500 }}
    >
      <DialogTitle>Mit SchochAuftrag verknüpfen</DialogTitle>
      <DialogContent>
        <Stack gap={2} paddingTop={1}>
          <TextField
            sx={{ flex: 1 }}
            value={props.connectAccount.firmLicence}
            label="Firmen-Lizenz"
            type="text"
            onChange={updateFirmLicence}
            size="small"
          />
          <TextField
            sx={{ flex: 1 }}
            value={props.connectAccount.userLicence}
            label="Benutzer-Lizenz"
            type="text"
            onChange={updateUserLicence}
            size="small"
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{paddingBottom: 2, paddingRight: 2}}>
        <Button variant="contained" type="submit" onClick={props.onConfirm}>Verknüpfen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default JoinFirmDialog;