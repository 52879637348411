import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Button, TextField, Autocomplete, Stack, Typography, SelectChangeEvent, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Worktime, defaultWorktime } from "../../models/worktime";
import { MeContext } from "../Provider/MeContextProvider";
import { User } from "../../models/user";
import WorktimeType, { worktimeOptions } from "../../models/worktime-type";

type WorktimeDialogProps = {
  worktime: Worktime | null;
  index: number;
  lockedName: boolean;
  isOpen: boolean;
  onSave: (worktime: Worktime, index: number) => void;
  onClose: () => void;
};

const WorktimeDialog = (props: WorktimeDialogProps) => {
  const meContext = useContext(MeContext);
  const [worktime, setWorktime] = useState<Worktime>(defaultWorktime);
  const [searchTerm, setSearchTerm] = useState('');
  const [displayError, setDisplayError] = useState(false);

  const updateWorktime = (key: keyof Worktime) => (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    if (key === 'date') {
      setWorktime(prev => ({ ...prev, ['date']: !!evt.target.value ? new Date(newValue) : new Date(0) }));
    } else if (key === 'timeInHours') {
      const value = Number(evt.target.value);
      if (!isNaN(value) && value >= 0) {
        setWorktime(prev => ({ ...prev, timeInHours: value }));
      }
    } else {
      setWorktime(prev => ({ ...prev, [key]: newValue}));
    }
  };

  const updateUser = (newValue: User | null) => {
    setWorktime(prev => ({ ...prev, user: newValue, username: newValue?.username ?? "" }));
  };

  const handleTypeChange = (event: SelectChangeEvent<WorktimeType>) => {
    const newType = event.target.value as WorktimeType;
    setWorktime(prev => ({ ...prev, type: newType }));
  };

  const filteredUsers = (meContext.firmUsers === null) ? [] : meContext.firmUsers.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const setToNow = () => {
    setWorktime(prev => ({ ...prev, date: new Date() }));
  };

  const assignToMe = () => {
    if (meContext.currentUser === null) {
      return;
    }

    setWorktime(prev => ({ ...prev, user: meContext.currentUser, username: meContext.currentUser!.username }));
  };

  const reset = () => {
    setWorktime({ ...defaultWorktime });
    setDisplayError(false);
  };

  const save = () => {
    if (worktime.date === null) {
      setDisplayError(true);
      return;
    } else {
      setWorktime(prev => ({ ...prev, type: 'work' }));
    }
    
    props.onSave(worktime, props.index);
    reset();
  };

  useEffect(() => {
    if (props.worktime !== null) {
      setWorktime(props.worktime);
    } else {
      reset();
    }

  }, [props.worktime]);

  return (
  <Dialog
    open={props.isOpen}
    onClose={props.onClose}
  >
    <DialogTitle>Arbeitszeit</DialogTitle>
    <DialogContent>
      <Stack flexWrap='wrap' gap={2}>
        {props.lockedName ?
          <Box>
            <Typography paddingLeft={2}>{worktime.user?.username}</Typography>
          </Box> :
          <Box display='flex' flexDirection='row' alignItems='center' paddingTop={1}>
            <Autocomplete
              size="small"
              sx={{ flex: 1 }}
              noOptionsText='Keine Treffer'
              options={filteredUsers}
              getOptionLabel={(user) => user.username}
              value={filteredUsers.find((user) => user.id === worktime.user?.id) || null}
              renderInput={(params) => <TextField {...params} variant="outlined" label='Name' onChange={(e) => setSearchTerm(e.target.value)} />}
              onChange={(_, newValue) => updateUser(newValue)}
            />
            <Button onClick={assignToMe}>Ich</Button>
          </Box>
        }
        <Box display='flex' flexDirection='row'>
          <TextField
            value={worktime.date?.toLocaleDateString('sv-SE') ?? ''}
            type='date'
            onChange={updateWorktime("date")}
            size='small'
            error={displayError}
            helperText={displayError ? 'Datum wird benötigt' : ''}
          />
          <Button onClick={setToNow}>Heute</Button>
        </Box>
        <TextField
          value={worktime.timeInHours === 0 ? '' : worktime.timeInHours}
          label='Arbeitszeit in Stunden'
          type="number"
          size="small"
          onChange={updateWorktime("timeInHours")}
        />
        {props.lockedName &&
          <FormControl>
            <InputLabel>Erfassungs-Typ</InputLabel>
            <Select
              value={worktime.type}
              size="small"
              onChange={handleTypeChange}
              label="Erfassungs-Typ"
            >
              {worktimeOptions.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        <TextField
          value={worktime.description}
          label='Beschreibung'
          type="text"
          size="small"
          onChange={updateWorktime("description")}
        />
      </Stack>
    </DialogContent>
    <DialogActions sx={{paddingBottom: 2, paddingRight: 2}}>
      <Button onClick={save}>Speichern</Button>
    </DialogActions>
  </Dialog>
  );
}

export default WorktimeDialog;