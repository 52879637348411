import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { SchochStack, SlideUpTransition } from '../../styles/styles';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { RapportFilter } from '../../models/rapport-filter';

type FilterDialogProps = {
  isOpen: boolean;
  onClose: () => Promise<void>;
  rapportFilter: RapportFilter;
  setRapportFilter: Dispatch<SetStateAction<RapportFilter>>;
}

const FilterDialog = (props: FilterDialogProps) => {

  const handleJobDoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const jobDone = (event.target as HTMLInputElement).value;
    props.setRapportFilter({ ...props.rapportFilter, jobDone: jobDone === 'true' ? true : jobDone === 'false' ? false : undefined });
  };

  const handleBilledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const billed = (event.target as HTMLInputElement).value;
    props.setRapportFilter({ ...props.rapportFilter, billed: billed === 'true' ? true : billed === 'false' ? false : undefined });
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={SlideUpTransition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>Filter</Typography>
        </Toolbar>
      </AppBar>
      <Stack sx={{ padding: { sm: 2 }, marginTop: { xs: 2 }, gap: 2, }}>
        <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm : 'row'}, gap: 2 }}>
          <SchochStack>
            <FormControl>
              <RadioGroup
                value={props.rapportFilter.jobDone?.toString() || 'all'}
                onChange={handleJobDoneChange}
              >
                <FormControlLabel value="all" control={<Radio />} label="--" />
                <FormControlLabel value="true" control={<Radio />} label="Arbeit erledigt" />
                <FormControlLabel value="false" control={<Radio />} label="Arbeit nicht erledigt" />
              </RadioGroup>
            </FormControl>
          </SchochStack>
          <SchochStack>
            <FormControl>
              <RadioGroup
                value={props.rapportFilter.billed?.toString() || 'all'}
                onChange={handleBilledChange}
              >
                <FormControlLabel value="all" control={<Radio />} label="--" />
                <FormControlLabel value="true" control={<Radio />} label="Verrechnet" />
                <FormControlLabel value="false" control={<Radio />} label="Nicht verrechnet" />
              </RadioGroup>
            </FormControl>
          </SchochStack>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default FilterDialog;
