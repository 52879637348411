import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { ReactNode } from "react";

type HelpDialogProps = {
  title: string;
  description: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const HelpDialog = (props: HelpDialogProps) => {
  return (
    <Dialog
      onClose={props.onClose}
      open={props.isOpen}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        {props.description}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose}>
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;