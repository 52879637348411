import "./../styles.css";
import Titlebar from "../components/Titlebar";
import { Box, Link, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SchochStack } from "../styles/styles";

const SupportPage: React.FC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Stack>
      <Titlebar title="Support" iconName="Support" />
      <Stack sx={{ padding: { sm: 2 }, paddingTop: 0, gap: 2 }}>
        <SchochStack>
          <Typography variant="h6">Kontakt</Typography>
          <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
            <Typography textAlign={'center'} variant="subtitle2">Telefonische Verfügbarkeit</Typography>
            <Typography textAlign='center' variant="h6">Montag bis Freitag</Typography>
          </Paper>
          <Box sx={{ display: 'flex', flexDirection: isXs ? 'column' : 'row', gap: 2 }}>
            <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
              <Typography textAlign={'center'} variant="subtitle2">Vormittag</Typography>
              <Typography textAlign='center' variant="h6">09:00 Uhr bis 12:00 Uhr</Typography>
            </Paper>
            <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
              <Typography textAlign={'center'} variant="subtitle2">Nachmittag</Typography>
              <Typography textAlign='center' variant="h6">14:00 Uhr bis 17:00 Uhr</Typography>
            </Paper>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: isXs ? 'column' : 'row', gap: 2 }}>
            <Paper sx={{ padding: 2, flex: 1, borderRadius: 0, background: theme => theme.palette.primary.lighter }}>
              <Typography textAlign={'center'} variant="subtitle2">Telefon</Typography>
              <Typography textAlign='center' variant="h6">044 938 00 28</Typography>
            </Paper>
            <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
              <Typography textAlign={'center'} variant="subtitle2">Email</Typography>
              <Typography textAlign='center' variant="h6">
                <Link href="mailto:info@npkelektro.ch" target="_blank" rel="noopener noreferrer">info@npkelektro.ch</Link>
              </Typography>
            </Paper>
          </Box>
        </SchochStack>
        <SchochStack>
          <Typography variant="h6">Über uns</Typography>
          <Typography>Schoch Informatik Hinwil AG</Typography>
          <Typography>untere Bahnhofstrasse 25</Typography>
          <Typography>8340 Hinwil</Typography>
        </SchochStack>
      </Stack>
    </Stack>
  );
};

export default SupportPage;