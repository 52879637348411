import { User } from "./user";
import WorktimeType from "./worktime-type";

export type Worktime = {
  id: number;
  date: Date;
  user: User | null;
  timeInHours: number;
  type: WorktimeType;
  description: string;
  isChecked: boolean;
  isRemoved: boolean | null;
  copyId: number | null;
  username: string;
  ic: string;
  price: number;
  productId: string;
};

export const defaultWorktime: Worktime = {
  id: 0,
  date: new Date(),
  user: null,
  timeInHours: 0,
  type: "work",
  description: '',
  isChecked: false,
  isRemoved: null,
  copyId: null,
  username: "",
  ic: "",
  price: 0,
  productId: "",
}