import React, { useState, useEffect } from 'react';
import { PDFDocument, PDFForm } from 'pdf-lib';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Button, TextField, Stack, Box, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { pdfjs } from 'react-pdf';

const FormsItem = () => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [pdfOpen, setPdfOpen] = useState(false);

  // Function to fill and update the PDF
  const loadAndFillPdf = async () => {
    try {
      const response = await fetch('/pdfs/batisec-formular.pdf');
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const form = pdfDoc.getForm();

      // Fill the form field with the text field value
      form.getTextField("Text00003").setText(textFieldValue);

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);

      return () => {
        if (url) {
          URL.revokeObjectURL(url);
        }
      };
    } catch (error) {
      console.error('Error loading and filling PDF:', error);
    }
  };

  // Use useEffect to update the PDF when textFieldValue changes
  useEffect(() => {
    if (textFieldValue) {
      loadAndFillPdf();
    }
  }, [textFieldValue]);

  return (
    <Stack sx={{ gap: 2, marginTop: 2 }}>
      <Box>
        <IconButton onClick={() => setPdfOpen(prev => !prev)}>
          {pdfOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
      {pdfOpen && (
        <>
          <TextField
            label="Text Field for Text00003"
            variant="outlined"
            fullWidth
            value={textFieldValue}
            onChange={(e) => setTextFieldValue(e.target.value)}
          />
          {pdfUrl && (
            <Box mt={2} sx={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden' }}>
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                <Viewer fileUrl={pdfUrl} />
              </Worker>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default FormsItem;
