import { Alert, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NoFirmAlert = () => {
  const navigate = useNavigate();
  
  return (
    <Alert severity="info">
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, alignItems: 'center', }}>
        <Typography variant="inherit">Um die volle Funktionalität der App verwenden zu können, verknüpfen Sie ihren Account mit SchochAUFTRAG.</Typography>
        <Button variant="contained" color="info" sx={{ width: 200, }} onClick={() => navigate("/firm")}>
          Verknüpfen
        </Button>
      </Box>
    </Alert>
  );
}

export default NoFirmAlert;