import axios from 'axios';
import LocalStorageHelper from '../helpers/local-storage-helper';
import { JwtDto } from '../dto/jwt-dto';
import config from '../config';

const authorizedApi = axios.create({
  baseURL: `${config.apiUrl}`,
  timeout: 20000,
});

authorizedApi.interceptors.request.use(
  (config) => {
    const token = LocalStorageHelper.getSessionToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);
  
authorizedApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const jwtDto: JwtDto = {
          refreshToken: LocalStorageHelper.getRefreshToken(),
          accessToken: LocalStorageHelper.getSessionToken(),
          refreshExpiryDate: LocalStorageHelper.getExpiryDate(),
        };

        const response = await axios.post(`${config.apiUrl}/users/refresh-token`, jwtDto);
        LocalStorageHelper.storeSessionToken(response.data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;

        return axios(originalRequest);
      } catch (refreshError) {
        LocalStorageHelper.removeRefreshToken();
        LocalStorageHelper.removeSessionToken();
        LocalStorageHelper.removeExpiryDate();
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);


export default authorizedApi;