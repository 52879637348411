import { AddressDto } from "../dto/address-dto";
import authorizedApi from "./api";

const AddressAPI = {
  syncAddressBookQuarantine: async () => {
    await authorizedApi.get('/address-book/sync', { timeout: 60000});
  },

  getAddressCount: async (searchTerm?: string): Promise<number> => {
    let queryParams: any = {};

    if (searchTerm !== undefined && searchTerm !== "") {
      queryParams.searchTerm = searchTerm;
    }

    const response = await authorizedApi.get(`/address-book/count`, { params: queryParams });
    return response.data as number;
  },

  getAllAddresses: async (page?: number, pageSize?: number, searchTerm?: string): Promise<AddressDto[]> => {
    let queryParams: any = {};

    if (page !== undefined) {
      queryParams.page = page;
    }

    if (pageSize !== undefined) {
      queryParams.pageSize = pageSize;
    }

    if (searchTerm !== undefined && searchTerm !== "") {
      queryParams.searchTerm = searchTerm;
    }

    const response = await authorizedApi.get('/address-book', { params: queryParams });
    return response.data as AddressDto[];
  },

  getAddress: async (id: number): Promise<AddressDto> => {
    const response = await authorizedApi.get(`/address-book/${id}`);
    return response.data as AddressDto;
  },

  addAddress: async (address: AddressDto): Promise<number> => {
    const response = await authorizedApi.post(`/address-book/`, address);
    return response.data as number;
  },

  updateAddress: async (id: number, address: AddressDto): Promise<number> => {
    const response = await authorizedApi.put(`/address-book/${id}`, address);
    return response.data as number;
  },

  deleteAddress: async (id: number) => {
    await authorizedApi.delete(`/address-book/${id}`);
  },
}

export default AddressAPI;