import Titlebar from "../components/Titlebar";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import { User, defaultUser } from "../models/user";
import UserFirmService from "../services/user-firm-service";
import { SchochStack } from "../styles/styles";
import UserService from "../services/user-service";
import { ConfirmDialog } from "../components/Dialogs/Dialog";
import debounce from "lodash/debounce";
import { MeContext } from "../components/Provider/MeContextProvider";

const ProfilePage: React.FC = () => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState<User>(defaultUser);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [adminCount, setAdminCount] = useState(0);

  const updateFirstname = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCurrentUser({...currentUser, firstName: newValue});
  };

  const updateLastname = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCurrentUser({...currentUser, lastName: newValue});
  };

  const updateUsername = async (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCurrentUser({...currentUser, username: newValue});
    if (!!newValue) {
      if (newValue === meContext.currentUser?.username) {
        setIsUsernameAvailable(true);
      } else {
        checkUsernameAvailability(newValue);
      }
    } else {
      setIsUsernameAvailable(true);
    }
  };

  const checkUsernameAvailability = useCallback(debounce(async (value: string) => {
    const isAvailable = await UserService.usernameExists(value);
    setIsUsernameAvailable(!isAvailable);
  }, 400), []);

  const saveProfile = async () => {

    let username = currentUser.username;
    if (!!!username && !!meContext.currentUser) {
      username = meContext.currentUser.username; 
    }

    if (meContext.currentUser?.username !== username) {
      const usernameExists = await UserService.usernameExists(currentUser.username);
      if (usernameExists) {
        context.setAlertMessage("Der ausgewählte Benutzername existiert bereits.");
        context.setAlertSeverity("error");
        return;
      }
    }

    let firstname = currentUser.firstName;
    if (!!!firstname) {
      firstname = !!meContext.currentUser ? meContext.currentUser.firstName : "";
    }

    let lastname = currentUser.lastName;
    if (!!!lastname) {
      lastname = !!meContext.currentUser ? meContext.currentUser.lastName : "";
    }

    const id = await UserService.updateUser({firstName: firstname, lastName: lastname, username: username} as User);
    if (id === null) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
      return;
    }

    context.setAlertMessage("Die Änderungen wurden erfolgreich gespeichert.");
    context.setAlertSeverity("success");
    meContext.setCurrentUser({...meContext.currentUser!, firstName: firstname, lastName: lastname, username: username});
  }

  const fetchAdminCount = async () => {
    const data = await context.handleAsyncOperation(
      () => UserFirmService.getMemberCount(true)
    );
    if (data !== null) {
      setAdminCount(data);
    }
  }

  const leaveFirm = async () => {
    setOpenDialog(false);

    if (meContext.userLicence === null || meContext.currentFirm === null) {
      return;
    }

    await context.handleAsyncOperation(
      () => UserFirmService.leaveFirm(meContext.currentFirm!.firmLicence, meContext.userLicence!, meContext.currentUser!.id),
    );

    window.location.reload();
  };

  useEffect(() => {
    fetchAdminCount();
  }, []);

  return (
    <Stack>
      <Titlebar title="Profil" iconName="Person" />
      <Stack sx={{ padding: { sm: 2 }, paddingTop: 0, gap: 2, }} >
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, }}>
          <SchochStack>
            <Typography variant="h6">Über mich</Typography>
            <Typography><strong>Vorname:</strong> {meContext.currentUser?.firstName}</Typography>
            <Typography><strong>Nachname:</strong> {meContext.currentUser?.lastName}</Typography>
            <Typography><strong>Benutzername:</strong> {meContext.currentUser?.username}</Typography>
          </SchochStack>
          <SchochStack>
            <Typography variant="h6">Persönliche Info ändern</Typography>
            <TextField
              value={currentUser.firstName}
              label='Vorname'
              type="text"
              size="small"
              onChange={updateFirstname}
            />
            <TextField
              value={currentUser.lastName}
              label='Nachname'
              type="text"
              size="small"
              onChange={updateLastname}
            />
            {/*<TextField
              value={currentUser.username}
              label='Benutzername'
              type="text"
              size="small"
              onChange={updateUsername}
              error={!isUsernameAvailable}
              helperText={!isUsernameAvailable ? "Benutzername nicht verfügbar" : ""}
            />*/}
            <Button sx={{ width: 200 }} variant="contained" onClick={saveProfile}>Speichern</Button>
          </SchochStack>
        </Box>
        <SchochStack>
          <Typography variant="h6">Firma</Typography>
          <Typography>{meContext.currentFirm ? `Firmen-Name: ${meContext.currentFirm.firmName}` : 'Sie sind nicht Teil einer Firma.'}</Typography>
          {(meContext.currentFirm && adminCount <= 1 && meContext.currentUser?.isAdmin) && <Typography>Als einziger Admin können Sie die Firma nicht verlassen.</Typography>}
          {meContext.currentFirm && <Button sx={{ width: 200, }} variant="contained" disabled={adminCount <= 1 && meContext.currentUser?.isAdmin} onClick={() => setOpenDialog(true)}>Firma verlassen</Button>}
        </SchochStack>
      </Stack>
      <ConfirmDialog title="Firma verlassen" description="Wollen Sie die Firma wirklich verlassen?" isOpen={openDialog} onClose={() => setOpenDialog(false)} onConfirm={leaveFirm} />
    </Stack>
  );
};

export default ProfilePage;