import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
  }>;
}

const PWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent  | null>(null);
  const [installPromptVisible, setInstallPromptVisible] = useState(false);

  const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
    e.preventDefault();
    setDeferredPrompt(e);
    setInstallPromptVisible(true);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        setDeferredPrompt(null);
        setInstallPromptVisible(false);
      });
    }
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
    };
  }, []);
  
  return (
    <Stack alignItems='center'>
      {installPromptVisible &&
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'fixed', bottom: 50, background: theme => theme.palette.primary.lighter, border: 1, margin: 2, padding: 2, boxShadow: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }} gap={2}>
            <Typography>Wollen Sie die App installieren?</Typography>
            <Button variant="contained" onClick={handleInstallClick}>Installieren</Button>
          </Box>
          <IconButton onClick={() => setInstallPromptVisible(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
    </Stack>
  );
};

export default PWAInstallPrompt;