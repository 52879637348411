import { Stack } from "@mui/material";
import Titlebar from "../components/Titlebar";
import EditRapport from "../components/EditRapport";
import RapportContextProvider from "../components/Provider/RapportContextProvider";

const EditPage: React.FC = () => {
  return (
    <Stack>
      <Titlebar title="Rapport bearbeiten" iconName="" />
      <RapportContextProvider>
        <EditRapport />
      </RapportContextProvider>
    </Stack>
  );
};

export default EditPage;