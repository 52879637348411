import { Button, Stack } from "@mui/material";
import NotFoundSvg from "../svg/NotFoundSvg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MetaContext } from "../components/Provider/MetaContextProvider";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const context = useContext(MetaContext);

  return (
    <Stack alignItems='center' gap={2}>
      <NotFoundSvg />
      {!context.isAuthenticated && <Button sx={{ width: 200, justifySelf: 'center' }} variant="contained" onClick={() => navigate('/')}>Zur Startseite</Button>}
    </Stack>
  );
};

export default NotFoundPage;