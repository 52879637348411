import { Avatar, Box, Button, IconButton, Stack, Theme, Typography } from "@mui/material";
import SchochLogo from "../svg/SchochLogo";
import AppsIcon from '@mui/icons-material/Apps';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import StoreIcon from '@mui/icons-material/Store';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportIcon from '@mui/icons-material/Support';
import { useContext, useState } from "react";
import { MeContext } from "./Provider/MeContextProvider";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import ProfileContextmenu from "./Contextmenu/ProfileContextmenu";

const Sidebar = () => {
  const navigate = useNavigate()
  const meContext = useContext(MeContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Stack sx={{
      width: 250,
      background: theme => theme.palette.primary.light,
      position: 'sticky',
      top: 0,
      height: '100vh',
      justifyContent: 'space-between',
      borderRight: 2,
      borderColor: theme => theme.palette.grey[800],
    }}>
      <Stack sx={{ gap: 3, padding: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <SchochLogo size={40} />
          <Typography variant="h5">Schoch</Typography>
        </Box>
        <Stack sx={{ alignItems: 'start', gap: 1, width: '100%' }}>
          <Typography variant="overline">Hauptmenü</Typography>
          <Button variant="text" color="inherit" sx={{ gap: 1, width: '100%', justifyContent: 'start' }} onClick={() => navigate('/overview')}>
            <AppsIcon />
            Übersicht
          </Button>
          <Button variant="text" color="inherit" sx={{ gap: 1, width: '100%', justifyContent: 'start' }} onClick={() => navigate('/list')}>
            <FormatListBulletedIcon />
            Rapporte
          </Button>
          <Button variant="text" color="inherit" sx={{ gap: 1, width: '100%', justifyContent: 'start' }} onClick={() => navigate('/time')}>
            <AccessTimeIcon />
            Zeiterfassung
          </Button>
          <Button variant="text" color="inherit" sx={{ gap: 1, width: '100%', justifyContent: 'start' }} onClick={() => navigate('/firm')}>
            <StoreIcon />
            Firma
          </Button>
        </Stack>
        <Stack sx={{ alignItems: 'start', gap: 1}}>
          <Typography variant="overline">Hilfe und Einstellungen</Typography>
          <Button variant="text" color="inherit" sx={{ gap: 1, width: '100%', justifyContent: 'start' }} onClick={() => navigate('/support')}>
            <SupportIcon />
            Hilfe
          </Button>
          <Button variant="text" color="inherit" sx={{ gap: 1, width: '100%', justifyContent: 'start' }} onClick={() => navigate('/settings')}>
            <SettingsIcon />
            Einstellungen
          </Button>
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, padding: 1, border: 1, borderRadius: 1, background: theme => theme.palette.primary.lighter, justifyContent: 'space-between', margin: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
        <Avatar variant="rounded" sx={{ background: theme => theme.palette.grey[100], color: 'black', width: 33, height: 33 }}>{meContext.currentUser?.username.charAt(0) ?? 'A'}</Avatar>
          <Stack>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{meContext.currentUser?.username ?? '(Kein Name)'}</Typography>
            <Typography variant="body2">{!!meContext.currentFirm?.firmLicence ? (meContext.currentUser?.isAdmin ? 'Admin' : 'Mitarbeiter') : 'Ohne Firma'}</Typography>
          </Stack>
        </Box>
        <IconButton size="small" sx={{ border: 1, borderRadius: 1, background: theme => theme.palette.primary.light, padding: 0.5 }} onClick={event => setAnchorEl(event.currentTarget)}>
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <ProfileContextmenu isOpen={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </Stack>
  );
};

export default Sidebar;