import FirmHelper from "../helpers/firm-helper";
import { Firm } from "../models/firm";
import FirmAPI from "../api/firm-api";
import { CreateUpdateFirm } from "../models/create-update-firm";

const FirmService = {
  firmExists: async (firmLicence: string): Promise<boolean> => {
    return await FirmAPI.firmExists(firmLicence);
  },

  syncCatalogLicences: async (): Promise<Firm> => {
    const firmDto = await FirmAPI.syncCatalogLicences();
    return FirmHelper.firmFromDto(firmDto);
  },

  getPriceStatus: async (): Promise<boolean> => {
    return FirmAPI.getPriceStatus();
  },

  getFirm: async (id: string): Promise<Firm | null> => {
    const firmDto = await FirmAPI.getFirm(id);
    if (!firmDto) {
      return null;
    }

    return FirmHelper.firmFromDto(firmDto);
  },

  createFirm: async (firmLicence: string, CreateUpdateFirm: CreateUpdateFirm): Promise<string> => {
    const firmDto = FirmHelper.createUpdateFirmToDto(CreateUpdateFirm);
    return await FirmAPI.createFirm(firmLicence, firmDto);
  },

  updateFirm: async (id: string, updateFirm: CreateUpdateFirm): Promise<string> => {
    const updateFirmDto = FirmHelper.createUpdateFirmToDto(updateFirm);
    return await FirmAPI.updateFirm(id, updateFirmDto);
  },

  deleteFirm: async (id: string) => {
    await FirmAPI.deleteFirm(id);
  },
};

export default FirmService;
