import { useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

type WeeklyDonutProps = {
  remaining: number;
  unpaid: number;
  compensation: number;
  worktime: number;
  vacation: number;
  sickness: number;
  target: number;
};

const WeeklyDonut = (props: WeeklyDonutProps) => {
  const theme = useTheme();
  
  const [series, setSeries] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  const [options, setOptions] = useState<ApexOptions>({});
  
  const formatHours = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const rest = minutes % 60;
    return `${hours}h ${rest}m`;
  };

  const getColors = (): string[] => {
    return [theme.palette.grey[400], theme.palette.primary.lighter!, theme.palette.primary.light, theme.palette.primary.main, theme.palette.primary.dark, theme.palette.primary.darker!];
  };

  const apexOptions = (): ApexOptions => {
    return {
      chart: {
        toolbar: {
          show: false
        },
      },
      labels: ['Restzeit', 'Unbezahlt', 'Kompensation', 'Arbeitszeit', 'Ferien', 'Absenz / Krankheit'],
      colors: getColors(),
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                color: '#000000',
                offsetY: -10,
              },
              value: {
                show: true,
                color: '#000000',
                formatter: (value: string) => {
                  return formatHours((Number.parseInt(value) ?? 0));
                }
              },
              total: {
                show: true,
                showAlways: true,
                label: (props.remaining > 0) ? 'Restzeit' : 'Überzeit',
                fontSize: '16px',
                color: '#000000',
                formatter: (w) => {
                  const time = Math.abs(w.config.series[0]);
                  const hours = Math.floor(time / 60);
                  const minutes = time % 60;
                  return `${hours}h ${minutes}m`;
                }
              }
            }
          }
        }
      },
    };
  };

  useEffect(() => {
    setSeries([props.remaining, props.unpaid, props.compensation, props.worktime, props.vacation, props.sickness]);
    setOptions(apexOptions());

  }, [props]);

  return (
    <ReactApexChart height={350} options={options} series={series} type="donut" />
  );
};

export default WeeklyDonut;