import Dialog from '@mui/material/Dialog';
import { SchochStickyRow, SlideUpTransition } from '../../styles/styles';
import { Box, InputAdornment, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import DialogTitlebar from '../DialogTitlebar';
import AddressService from '../../services/address-service';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { MetaContext } from '../Provider/MetaContextProvider';
import { Address, defaultAddress } from '../../models/address';
import AddressBookTable from '../AddressBookTable';
import debounce from 'lodash/debounce';
import { MeContext } from '../Provider/MeContextProvider';
import AddressDialog from './AddressDialog';
import SchochButton from '../Buttons/SchochButton';

type AddressBookDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  pagination: boolean;
  totalCount: number;
  fetchAddressBook: (page?: number, pageSize?: number, searchTerm?: string) => void;
  setAddressBook: Dispatch<SetStateAction<Address[]>>;
  addressBook: Address[];
}

const AddressBookDialog = (props: AddressBookDialogProps) => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleSyncButtonClick = async () => {
    context.handleAsyncOperation(
      AddressService.syncAddressBookQuarantine
    );

    context.setAlertSeverity("success");
    context.setAlertMessage("Das Adressbuch wurde erfolgreich synchronisiert.");
  };

  const handleSearchChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    debouncedSearch(newValue);
  };

  const debouncedSearch = useCallback(debounce((searchTerm?: string) => {
    props.fetchAddressBook(1, 100, searchTerm);
  }, 400), []);

  const saveAddress = async (address: Address) => {
    const id = await context.handleAsyncOperation(
      () => AddressService.addAddress(address)
    );

    const newAddress = { ...address, id: id };

    if (id !== null) {
      context.setAlertMessage('Die Adresse wurde erfolgreich gespeichert.');
      context.setAlertSeverity('success');
      props.setAddressBook(prev => ([...prev, newAddress]));
    } else {
      context.setAlertMessage("Ein Fehler ist beim Hinzufügen ins Adressbuch aufgetreten.");
      context.setAlertSeverity("error");
    }

    setIsOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitlebar title='Adressbuch' onClose={props.onClose}/>
      <Stack sx={{ overflowY: 'auto', gap: 2, paddingTop: '0 !important' , padding: { sm: 2 } }}>
        <SchochStickyRow sx={{ alignItems: 'center', justifyContent: 'space-between', padding: 1, paddingLeft: 2, }}>
          <TextField
            variant='standard'
            label='Adressen durchsuchen'
            onChange={handleSearchChange}
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            }}
          />
          {meContext.currentUser?.isAdmin && <Box display='flex' flexDirection='row' gap={2}>
            <SchochButton
              icon={<AddIcon />}
              text='Neu'
              disabled={!!!meContext.currentFirm}
              onClick={() => setIsOpen(true)}
            />
            <SchochButton
              icon={<SyncIcon />}
              text='Sync..'
              disabled={!!!meContext.currentFirm}
              onClick={handleSyncButtonClick}
            />
          </Box>}
        </SchochStickyRow>
        <AddressBookTable currentUser={meContext.currentUser} isDetailed={props.pagination} totalCount={props.totalCount} fetchAddressBook={props.fetchAddressBook} setAddressBook={props.setAddressBook} addressBook={props.addressBook} />
        <AddressDialog address={null} isOpen={isOpen} onSave={saveAddress} onClose={() => setIsOpen(false)} />
      </Stack>
    </Dialog>
  );
};

export default AddressBookDialog;