import Titlebar from "../components/Titlebar";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Box, Chip, Fab, InputAdornment, Skeleton, Stack, TextField, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MetaContext } from '../components/Provider/MetaContextProvider';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import FilterDialog from "../components/Dialogs/FilterDialog";
import { useNavigate } from "react-router-dom";
import RapportService from "../services/rapport-service";
import { Rapport } from "../models/rapport";
import { RapportFilter, defaultRapportFilter } from "../models/rapport-filter";
import debounce from "lodash/debounce";
import { subYears, startOfTomorrow, startOfToday, endOfToday, startOfYesterday, endOfYesterday, startOfMonth, endOfDay, subDays, startOfYear, endOfMonth, subMonths, endOfYear, isWithinInterval, addYears } from "date-fns";
import RapportListEntry from "../components/RapportListEntry";
import InventoryIcon from '@mui/icons-material/Inventory';
import DeleteIcon from '@mui/icons-material/Delete';
import { MeContext } from "../components/Provider/MeContextProvider";
import PersonIcon from '@mui/icons-material/Person';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SchochButton from "../components/Buttons/SchochButton";
import { SchochStack } from "../styles/styles";
import SchochToggleButton from "../components/Buttons/SchochToggleButton";
import NoFirmAlert from "../components/NoFirmAlert";

const RapportListPage: React.FC = () => {
  const navigate = useNavigate();
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const [rapports, setRapports] = useState<Rapport[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [rapportFilter, setRapportFilter] = useState<RapportFilter>(defaultRapportFilter);
  const [paperbin, setPaperbin] = useState(false);
  const [archive, setArchive] = useState(false);
  const [onlyMyRapports, setOnlyMyRapports] = useState(false);
  const [isReversed, setIsReversed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const currentDate = new Date();
  const months = [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ];
  const lastYear = subYears(currentDate, 1);
  const noDate = 'Ohne Datum';

  const timelineCategories = [
    { label: 'Kommend', dateRange: [startOfTomorrow(), endOfYear(new Date('9999-12-31'))] },
    { label: 'Heute', dateRange: [startOfToday(), endOfToday()] },
    { label: 'Gestern', dateRange: [startOfYesterday(), endOfYesterday()] },
    { label: months[currentDate.getMonth()], dateRange: [startOfMonth(currentDate), endOfDay(subDays(currentDate, 2))] },
    { label: currentDate.getFullYear(), dateRange: [startOfYear(currentDate), endOfMonth(subMonths(currentDate, 1))] },
    { label: lastYear.getFullYear(), dateRange: [startOfYear(lastYear), endOfYear(lastYear)] },
    { label: 'Älter', dateRange: [startOfYear(addYears(new Date(0), 1)), endOfYear(subYears(currentDate, 2))] },
    { label: noDate, dateRange: [startOfYear(new Date('0001-01-01')), endOfYear(new Date(0))]},
  ];

  const categoriesToMap = isReversed ? timelineCategories.reverse() : timelineCategories;

  const categorizedRapports: Record<string, Rapport[]> = {};
  rapports.forEach((rapport) => {
    const rapportDueDate = rapport.dueDate ? new Date(rapport.dueDate) : null;
  
    if (!rapportDueDate) {
      if (!categorizedRapports[noDate]) {
        categorizedRapports[noDate] = [];
      }
      categorizedRapports[noDate].push(rapport);
    } else {
      for (const category of timelineCategories) {
        if ((category.dateRange[0] <= category.dateRange[1]) && isWithinInterval(rapportDueDate, { start: category.dateRange[0], end: category.dateRange[1] })) {
          if (!categorizedRapports[category.label]) {
            categorizedRapports[category.label] = [];
          }
          
          categorizedRapports[category.label].push(rapport);
          break;
        }
      }
    }
  });

  const updateRapports = (rapport: Rapport) => {
    setRapports((prevRapports) =>
      prevRapports.map((prev) => (prev.id === rapport.id ? rapport : prev))
    );
  };

  const togglePaperbin = async () => {
    const paperbinActive = !paperbin;
    setPaperbin(paperbinActive);
    setArchive(false);
    await fetchRapports(rapportFilter.search, rapportFilter.jobDone, rapportFilter.billed, paperbinActive, false, onlyMyRapports);
  };

  const toggleArchive = async () => {
    const archiveActive = !archive;
    setArchive(archiveActive);
    setPaperbin(false);
    await fetchRapports(rapportFilter.search, rapportFilter.jobDone, rapportFilter.billed, false, archiveActive, onlyMyRapports);
  };

  const toggleMyRapports = async () => {
    const myRapports = !onlyMyRapports;
    setOnlyMyRapports(myRapports);
    await fetchRapports(rapportFilter.search, rapportFilter.jobDone, rapportFilter.billed, paperbin, archive, myRapports)
  };

  const toggleReversed = () => {
    setIsReversed(prev => !prev);
  };

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  };

  const isFilterActive = (): boolean => {
    return (rapportFilter.billed !== undefined || rapportFilter.jobDone !== undefined || !!rapportFilter.search);
  };

  const resetFilter = async () => {
    setRapportFilter(defaultRapportFilter);
    await fetchRapports(undefined, undefined, undefined, paperbin, archive, onlyMyRapports);
  };

  const searchRapports = useCallback(debounce(async (value: string, paperbinActive: boolean, archiveActive: boolean, jobDone?: boolean, billed?: boolean, myRapports?: boolean) => {
    const searchTerm = !!value ? value : undefined;
    await fetchRapports(searchTerm, jobDone, billed, paperbinActive, archiveActive, myRapports);
  }, 400), []);

  const handleSearchChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setRapportFilter({ ...rapportFilter, search: newValue });
    searchRapports(newValue, paperbin, archive, rapportFilter.jobDone, rapportFilter.billed);
  };
  
  const fetchRapports = async (search?: string, jobDone?: boolean, billed?: boolean, paperbin?: boolean, archive?: boolean, myRapports?: boolean) => {
    try {
      setIsLoading(true);
      const data = await RapportService.getRapports(undefined, undefined, search, jobDone, billed, paperbin, archive, myRapports);
      if (data !== null) {
        setRapports(data);
      }
    } finally {
      setIsLoading(false);
    }    
  };

  const initializeRapportList = async () => {
    try {
      setIsLoading(true);
      let newRapports: string[] = [];
      if (!!meContext.currentFirm) {
        try {
          newRapports = await RapportService.syncQuarantine();
        } finally {
          context.setSelectedRapportIds([...context.selectedRapportIds, ...newRapports]);
        }
        await fetchRapports(undefined, undefined, undefined, false, false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeRapportList();
    
    return () => {
      context.setSelectedRapportIds([]);
    };
  }, [meContext.currentFirm]);

  const searchBar = () => {
    return (
      <SchochStack sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'space-between', justifyContent:'space-between' }}>
        <TextField
          variant='standard'
          label='Rapporte durchsuchen'
          disabled={!!!meContext.currentFirm}
          value={rapportFilter.search}
          onChange={handleSearchChange}
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          }}
          sx={{ alignSelf: { xs: 'stretch', sm: 'auto' } }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
          {isFilterActive() && <Chip sx={{ alignSelf: 'center' }} icon={<ClearIcon />} color="primary" label="Filter" variant="filled" onClick={resetFilter} /> }
          <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
            <SchochButton variant={isReversed ? "contained" : "outlined"} icon={<SortByAlphaIcon />} disabled={!!!meContext.currentFirm} text="Sort.." onClick={toggleReversed} />
            <SchochButton variant={(rapportFilter.billed !== undefined || rapportFilter.jobDone !== undefined) ? "contained" : "outlined"} icon={<FilterListIcon />} disabled={!!!meContext.currentFirm} text="Filter" onClick={handleFilterOpen} />
            <SchochButton variant={onlyMyRapports ? "contained" : "outlined"} icon={<PersonIcon />} disabled={!!!meContext.currentFirm} text="Für mich" onClick={toggleMyRapports} />
            <ToggleButtonGroup color="primary" orientation={isXSScreen ? 'horizontal' : 'vertical'} size="small" value={archive ? 'archiv' : (paperbin ? 'paperbin' : '')}>
              <SchochToggleButton value='archiv' onClick={toggleArchive} icon={<InventoryIcon />} text="Archiv" />
              <SchochToggleButton value='paperbin' onClick={togglePaperbin} icon={<DeleteIcon />} text="Papierkorb" />
            </ToggleButtonGroup>
          </Box>
        </Box>
      </SchochStack>
    );
  };

  return (
    <Stack>
      <Titlebar title="Rapportliste" iconName="FormatListBulleted" />
      <Stack sx={{ overflowY: 'auto', gap: 2, padding: { sm: 2 }, paddingTop: 0 }}>
        {searchBar()}
        <Box>
      {isLoading ? (
        <Stack gap={2}>
          {[...Array(2)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={200} />
          ))}
        </Stack>
      ) : (
        !!meContext.currentFirm ? (
          <Stack gap={2}>
            {rapports.length === 0 && (
              <Alert severity="info">Es wurden keine Rapporte gefunden.</Alert>
            )}
            {categoriesToMap.map((category, index) => (
              <Stack
                key={index}
                sx={{
                  boxShadow: 2,
                  display: !!categorizedRapports[category.label] ? 'flex' : 'none',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    background: theme.palette.grey[400],
                    padding: 2,
                    border: 1,
                    borderColor: 'black',
                  }}
                >
                  <Typography variant="h6">{category.label}</Typography>
                </Box>
                <Stack>
                  {categorizedRapports[category.label]?.map((rapport) => (
                    <RapportListEntry
                      paperbin={paperbin}
                      archive={archive}
                      key={rapport.id}
                      rapport={rapport}
                      setRapports={setRapports}
                      updateRapport={updateRapports}
                    />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        ) : (
          <NoFirmAlert />
        )
      )}
    </Box>
      </Stack>
      <Fab
        sx={{
          position: 'fixed',
          bottom: (theme) => theme.spacing(10),
          right: (theme) => theme.spacing(3),
        }}
        color="primary"
        onClick={(event) => { event.stopPropagation(); navigate('/rapport'); }}
      >
        <AddIcon />
      </Fab>
      <FilterDialog
        isOpen={isFilterOpen}
        onClose={async () => { await fetchRapports(rapportFilter.search, rapportFilter.jobDone, rapportFilter.billed, paperbin, archive); setIsFilterOpen(false); }}
        rapportFilter={rapportFilter}
        setRapportFilter={setRapportFilter}
      />
    </Stack>
  );
};

export default RapportListPage;