import { format } from "date-fns";
import WorktimeAPI from "../api/worktime-api";
import WorktimeHelper from "../helpers/worktime-helper";
import { Worktime } from "../models/worktime";
import { WorktimeWeek } from "../models/worktime-week";

const WorktimeService = {
  getUncheckedWorktimes: async (): Promise<Worktime[]> => {
    const worktimeDtos = await WorktimeAPI.getUncheckedWorktimes();
    return worktimeDtos.map(x => WorktimeHelper.worktimeFromDto(x));
  },

  getWeek: async (startOfWeek: Date): Promise<WorktimeWeek> => {
    const formattedDate = format(startOfWeek, 'yyyy-MM-dd');
    const week = await WorktimeAPI.getWeek(formattedDate);
    return WorktimeHelper.worktimeWeekFromDto(week);
  },

  addWorktime: async (worktime: Worktime): Promise<number> => {
    const worktimeDto = WorktimeHelper.worktimeToDto(worktime);
    return await WorktimeAPI.addWorktime(worktimeDto);
  },

  updateWorktime: async (id: number, worktime: Worktime): Promise<number> => {
    const worktimeDto = WorktimeHelper.worktimeToDto(worktime);
    return await WorktimeAPI.updateWorktime(id, worktimeDto);
  },

  checkWorktime: async (id: number): Promise<number> => {
    return await WorktimeAPI.checkWorktime(id);
  },

  deleteWorktime: async (id: number) => {
    await WorktimeAPI.deleteWorktime(id);
  },

  deleteWorktimes: async (ids: number[]) => {
    await WorktimeAPI.deleteWorktimes(ids);
  },

  addOvertime: async (minutes: number): Promise<number> => {
    return await WorktimeAPI.addOvertime(minutes);
  },
}

export default WorktimeService;