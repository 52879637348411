import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, List, ListItem, Typography, Box, Stack, IconButton } from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { RapportContext } from "../Provider/RapportContextProvider";
import { MeContext } from "../Provider/MeContextProvider";
import { Comments } from "../../models/comment";
import RapportService from "../../services/rapport-service";
import DeleteIcon from "@mui/icons-material/Delete";

type CommentDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CommentDialog = (props: CommentDialogProps) => {
  const meContext = useContext(MeContext);
  const rapportContext = useContext(RapportContext);
  const [currentComment, setCurrentComment] = useState('');

  const updateCurrentComment = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCurrentComment(newValue);
  };

  const addComment = async () => {
    const newComment: Comments = {
      id: 0,
      rapportId: rapportContext.rapportId,
      commentContent: currentComment,
      creator: !!meContext.currentUser ? meContext.currentUser : null,
      createDate: null,
    };

    const commentId = await RapportService.updateComment(rapportContext.rapportId, newComment);
    const addedComment: Comments = {
      ...newComment,
      id: commentId,
    };

    setCurrentComment('');
    rapportContext.setRapport(prev => ({
      ...prev,
      comments: [...prev.comments, addedComment]
    }));
  };

  const deleteComment = async (comment: Comments) => {
    const deleteComment: Comments = {...comment, rapportId: null }; 

    await RapportService.updateComment(rapportContext.rapportId, deleteComment);

    rapportContext.setRapport(prev => ({
      ...prev,
      comments: prev.comments.filter(x => x.id !== deleteComment.id),
    }));
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      
    >
      <DialogTitle>Bemerkungen</DialogTitle>
      <DialogContent>
        {rapportContext.rapport.comments.length === 0 && <Typography variant="caption">Keine Bemerkungen gefunden</Typography>}
        <List>
          {rapportContext.rapport.comments.map((comment, index) => (
            <ListItem key={index} sx={{ paddingLeft: 0, paddingRight: 0, }}>
              <Stack sx={{ flex: 1, border: 1, boxShadow: 1, padding: 1, gap: 1, backgroundColor: theme => meContext.currentUser?.id === comment.creator?.id ? theme.palette.primary.lighter : theme.palette.grey[100], }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between' }}>
                  <Typography variant="caption">{!!comment.createDate ? comment.createDate.toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) : 'Jetzt'}</Typography>
                  <Typography variant="caption">{!!comment.creator ? comment.creator.username : 'Aus Alfred'}</Typography>
                </Box>
                <Box display='flex' flexDirection='row' justifyContent='space-between' gap={2}>
                  <Typography>{comment.commentContent}</Typography>
                  <IconButton sx={{ padding: 0}} onClick={() => deleteComment(comment)}><DeleteIcon /></IconButton>
                </Box>
              </Stack>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions sx={{paddingBottom: 2, paddingRight: 2}}>
        <TextField
          size="small"
          value={currentComment}
          onChange={updateCurrentComment}
        />
        <Button onClick={addComment}>Kommentieren</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentDialog;