import AttachmentAPI from "../api/attachment-api";
import AttachmentHelper from "../helpers/attachment-helper";
import { Attachment } from "../models/attachment";
import { FormInfo } from "../models/form-info";

const AttachmentService = {
  getAllForms: (): FormInfo[] => {
    return AttachmentHelper.getAllForms();
  },
  
  getAttachments: async (rapportId: string): Promise<Attachment[]> => {
    const attachmentDtos = await AttachmentAPI.getAttachments(rapportId);
    return attachmentDtos.map(attachmentDto => AttachmentHelper.attachmentFromDto(attachmentDto));
  },

  addAttachments: async (rapportId: string, files: File[]) => {
    const formData = AttachmentHelper.filesToFormData(files);
    await AttachmentAPI.addAttachments(rapportId, formData);
  },

  deleteAttachment: async (id: number) => {
    await AttachmentAPI.deleteAttachment(id);
  },
};

export default AttachmentService;